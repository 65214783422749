import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { RadioFilled, RadioOutlined } from '../Icons';
import * as typography from '../../styles/typography';

const IndicationRadioWrapper = styled.div`
  ${typography.bold};
  align-items: center;
  cursor: pointer;
  display: flex;
  & > * {
    margin-right: 8px;
  }
  margin-bottom: 4px;
`;

const IndicationRadio = ({ label, active, ...props }) => (
  <IndicationRadioWrapper {...props}>
    {active ? RadioFilled : RadioOutlined}
    {label}
  </IndicationRadioWrapper>
);

IndicationRadio.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
};

export default IndicationRadio;
