import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import * as Icons from '../Icons';
import {
  ListBodyRow,
  ListBodyRowContent,
  IconColumn,
  DataColumn,
  IconWrapper,
} from '../List';
import PolicyListRowMeta from './PolicyListRowMeta';
import { FIELDS } from './PolicyList.constants';
import usePolicySelections from '../../hooks/usePolicySelections';
import { policyPropType } from '../../propTypes';
import { useAppContext } from '../../providers/AppProvider';
import { useAuthContext } from '../../providers/AuthProvider';

// creates a method that we can pass directly into
// Array.find() to match on id & indication
const findPolicyWithIndication = (policy) => (p) =>
  p.id === policy.id &&
  p.indicationId === policy.indicationId &&
  p.productId === policy.productId;

const PolicyRowIcon = ({ onClick, disabled, icon }) => (
  <IconWrapper disabled={disabled} onClick={onClick}>
    {icon}
  </IconWrapper>
);
PolicyRowIcon.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
};

const PolicyListRow = ({
  product,
  policy,
  showMeta,
  showVisibleToPractice,
}) => {
  const { policies } = useAppContext();
  const { getIsPending, toggleFavoritePolicy, toggleVisiblePolicy } =
    usePolicySelections();
  const { search } = useLocation();
  const params = qs.parse(search);
  const productId = parseInt(params.productId);
  const { user } = useAuthContext();
  const makeHandleClickIcon = (policy, productId, callback) => (e) => {
    if (getIsPending(policy.id)) return;
    callback(policy, productId);
  };

  const isFavorite = policies.favorites.find(findPolicyWithIndication(policy));
  const isVisibleToPractice = policies.visible.find(
    findPolicyWithIndication(policy),
  );

  const fields = product?.fields ?? FIELDS;

  return (
    <ListBodyRow>
      <ListBodyRowContent>
        {showVisibleToPractice && (
          <IconColumn>
            <PolicyRowIcon
              disabled={getIsPending(policy.id)}
              icon={isVisibleToPractice ? Icons.ToggleOn : Icons.ToggleOff}
              onClick={makeHandleClickIcon(
                policy,
                productId,
                toggleVisiblePolicy,
              )}
            />
          </IconColumn>
        )}
        {user.role === 'rbm' ? (
          <></>
        ) : (
          <IconColumn>
            <PolicyRowIcon
              disabled={getIsPending(policy.id)}
              icon={
                isFavorite ? (
                  <Icons.StarFilled color={product?.color} />
                ) : (
                  Icons.StarOutlined
                )
              }
              onClick={makeHandleClickIcon(
                policy,
                productId,
                toggleFavoritePolicy,
              )}
            />
          </IconColumn>
        )}
        {fields.map(({ columnWidth, value }) => (
          <DataColumn key={value} width={columnWidth}>
            {policy[value]}
          </DataColumn>
        ))}
      </ListBodyRowContent>

      {showMeta && (
        <PolicyListRowMeta
          showVisibleToPractice={showVisibleToPractice}
          policy={policy}
        />
      )}
    </ListBodyRow>
  );
};

PolicyListRow.propTypes = {
  product: PropTypes.object,
  policy: policyPropType.isRequired,
  showMeta: PropTypes.bool,
  showVisibleToPractice: PropTypes.bool.isRequired,
};

PolicyListRow.defaultProps = {
  showMeta: false,
};

export default PolicyListRow;
