/*
  All Column Widths should add up to ~80-85%, which leaves a decent
  bit of space for the Icon columns in the table to occupy
*/

export const FIELDS = [
  { columnWidth: '14%', label: 'Insurer', value: 'controller' },
  { columnWidth: '13%', label: 'Health Plan(s)', value: 'name' },
  { columnWidth: '8%', label: 'Status', value: 'coverageStatus' },
  { columnWidth: '8%', label: 'Step Edit', value: 'stepStatus' },
  { columnWidth: '8%', label: 'Step Statement', value: 'stepStatement' },
  {
    columnWidth: '8%',
    label: 'PA Required',
    value: 'paRequired',
  },
];
