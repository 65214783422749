import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import qs from 'query-string';

import { useHistory } from 'react-router-dom';

import Select from '../components/Select';
import PageWrapper from '../components/PageWrapper';
import PageHeading from '../components/PageHeading';
import PageSubheading from '../components/PageSubheading';
import USAMap from '../components/USAMap';
import JumboButton from '../components/JumboButton';
import ProductIndicator from '../components/ProductIndicator';
import { device } from '../styles/breakpoints';
import { darkTheme } from '../styles/themes';
import {
  CHANNEL_OPTIONS,
  STATE_AND_TERRITORY_OPTIONS,
  LIBTAYO_ID,
} from '../constants';
import { getCoverageTitle, getProductById } from '../helpers';
import { useAuthContext } from '../providers/AuthProvider';

const SelectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const SelectContainer = styled.div`
  flex-basis: 20%;
  flex-grow: 1;
  margin-bottom: 4px;
  padding: 0 6px;
`;

const FlexWrapBreak = styled.div`
  flex-basis: 100%;
  height: 0;
  @media ${device.laptop} {
    flex-basis: 0;
  }
`;

const ContinueButton = styled(JumboButton).attrs({
  theme: darkTheme,
})`
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 1rem 6rem;
`;

const CoverageSelection = () => {
  const history = useHistory();
  const { user } = useAuthContext();

  const product = getProductById(LIBTAYO_ID);
  const [usState, setUSState] = useState(null);
  const [channel, setChannel] = useState(null);
  const [indication, setIndication] = useState(null);

  const isIndicationSelectionDisabled = !product;
  const isUSStateSelectionDisabled = !channel || !indication;
  const isContinueButtonDisabled = isUSStateSelectionDisabled || !usState;

  useEffect(() => {
    const isIndicationAvailable = product?.indications.includes(indication);
    if (!isIndicationAvailable) {
      setIndication(null);
    }
  }, [product]);

  const handleClickContinue = () => {
    const params = {
      productId: product.value,
      usState,
      channelId: channel.value,
      indicationId: indication.value,
    };
    // https://github.com/gabidavila/react-usa-map/issues/43
    if (params.usState === 'DC2') {
      params.usState = 'DC';
    }
    history.push(`/coverage/results?${qs.stringify(params)}`);
  };

  return (
    <PageWrapper>
      <PageHeading>{getCoverageTitle(user.role)}</PageHeading>
      <SelectsContainer>
        <SelectContainer>
          <ProductIndicator backgroundColor={product?.color}>
            {product?.expandedLabel}
          </ProductIndicator>
        </SelectContainer>
        <SelectContainer>
          <Select
            value={channel}
            placeholder="Select Channel:"
            options={CHANNEL_OPTIONS}
            onChange={setChannel}
          />
        </SelectContainer>
        <FlexWrapBreak />
        <SelectContainer>
          <Select
            isDisabled={isIndicationSelectionDisabled}
            value={indication}
            placeholder="Select Indication:"
            options={product?.indications ?? []}
            onChange={setIndication}
          />
        </SelectContainer>
        <SelectContainer>
          <Select
            isDisabled={isUSStateSelectionDisabled}
            onChange={setUSState}
            options={STATE_AND_TERRITORY_OPTIONS}
            placeholder="Select State/US Territory:"
            value={usState}
            valueProp="value"
          />
        </SelectContainer>
      </SelectsContainer>

      <PageSubheading
        style={isUSStateSelectionDisabled ? { visibility: 'hidden' } : null}
      >
        Choose State
      </PageSubheading>

      <USAMap
        disabled={isUSStateSelectionDisabled}
        value={usState}
        onClickState={setUSState}
      />

      <ContinueButton
        onClick={handleClickContinue}
        disabled={isContinueButtonDisabled}
      >
        Continue
      </ContinueButton>
    </PageWrapper>
  );
};

export default CoverageSelection;
