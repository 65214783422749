import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import PageFooter from '../components/PageFooter';
import JumboButton from '../components/JumboButton';
import routes from '../constants/routes';
import * as colors from '../styles/colors';
import { appGutters } from '../styles/constants';
import * as typography from '../styles/typography';
import { darkTheme } from '../styles/themes';
import libtayoLogo from '../assets/images/libtayo_logo.png';

const StyledPageFooter = styled(PageFooter)`
  margin-top: 4rem;
`;

const NotFoundWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 60px ${appGutters};
  width: 100%;
  box-sizing: border-box;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  margin: 0 35px;
`;

const Header = styled.h1`
  ${typography.h1};
  ${typography.bold};
  color: ${colors.darkBlue};
`;

const ErrorText = styled.h3`
  ${typography.h5};
  ${typography.bold};
  margin: 5px 0 35px;
  color: ${colors.darkBlue};
`;

const NotFound = () => {
  const history = useHistory();
  const goHome = () => {
    history.push(routes.home);
  };

  return (
    <NotFoundWrapper>
      <ImageWrapper>
        <Logo alt="Libtayo" src={libtayoLogo} />
      </ImageWrapper>
      <Header>404 Error</Header>
      <ErrorText>Sorry, we cannot find the page you are looking for.</ErrorText>
      <ThemeProvider theme={darkTheme}>
        <JumboButton onClick={goHome}>
          Add Plans to Your Practice Portal
        </JumboButton>
      </ThemeProvider>
      <StyledPageFooter />
    </NotFoundWrapper>
  );
};

export default NotFound;
