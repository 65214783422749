import React from 'react';
import styled from 'styled-components';
import { pathwayStatusPropType } from '../../propTypes';
import { FIELDS, DATA_COLUMN_MARGIN_LEFT } from './constants';
import { ListBodyRow, ListBodyRowContent, DataColumn } from '../List';

const PathwayStatusDataColumn = styled(DataColumn)`
  margin-left: ${DATA_COLUMN_MARGIN_LEFT};
`;

const PathwayStatusListRow = ({ pathwayStatus }) => {
  return (
    <ListBodyRow>
      <ListBodyRowContent>
        {FIELDS.map(({ columnWidth, value }) => (
          <PathwayStatusDataColumn key={value} width={columnWidth}>
            {pathwayStatus[value]}
          </PathwayStatusDataColumn>
        ))}
      </ListBodyRowContent>
    </ListBodyRow>
  );
};

PathwayStatusListRow.propTypes = {
  pathwayStatus: pathwayStatusPropType.isRequired,
};

export default PathwayStatusListRow;
