import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select, { selectStyles } from './Select';
import ProductIndicator from './ProductIndicator';
import { device } from '../styles/breakpoints';
import * as colors from '../styles/colors';
import {
  PATHWAY_STATUS_INDICATION_OPTIONS,
  DISABLED_PATHWAY_STATUS_INDICATION_IDS,
  STATE_AND_TERRITORY_OPTIONS,
} from '../constants';
import {
  productPropType,
  indicationPropType,
  usStatePropType,
} from '../propTypes';

const SelectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 130px;
`;

const SelectContainer = styled.div`
  flex-basis: 20%;
  flex-grow: 1;
  margin-bottom: 4px;
  padding: 0 6px;
`;

const FlexWrapBreak = styled.div`
  flex-basis: 100%;
  height: 0;
  @media ${device.laptop} {
    flex-basis: 0;
  }
`;

/**
 * Styles indication select to improve differentation between enabled
 * and disabled select options per client request:
 */
const pathwayIndicationSelectStyles = {
  ...selectStyles,
  option: (provided, { isDisabled, isFocused, data }) => ({
    ...provided,
    margin: 0,
    backgroundColor: (() => {
      if (isFocused) {
        return colors.greyscale[100];
      } else {
        return colors.white;
      }
    })(),
    color: (() => {
      if (isDisabled) {
        return colors.greyscale[200];
      } else {
        return colors.darkBlue;
      }
    })(),
    fontFamily: 'Arial Bold',
    fontWeight: 'bold',
    cursor: 'pointer',
  }),
};

const PathwaySelect = ({
  product,
  indication,
  usState,
  setIndication,
  setUsState,
}) => {
  const isIndicationSelectionDisabled = !product;
  const isUsStateSelectionDisabled = !indication;
  const isIndicationOptionDisabled = (option) =>
    DISABLED_PATHWAY_STATUS_INDICATION_IDS.includes(option.value);

  return (
    <SelectsContainer>
      <SelectContainer>
        <ProductIndicator backgroundColor={product?.color}>
          {product?.expandedLabel}
        </ProductIndicator>
      </SelectContainer>

      <SelectContainer>
        <Select
          isDisabled={isIndicationSelectionDisabled}
          value={indication}
          placeholder="Select Indication:"
          options={PATHWAY_STATUS_INDICATION_OPTIONS}
          onChange={setIndication}
          isOptionDisabled={isIndicationOptionDisabled}
          styles={pathwayIndicationSelectStyles}
        />
      </SelectContainer>

      <FlexWrapBreak />

      <SelectContainer>
        <Select
          isDisabled={isUsStateSelectionDisabled}
          onChange={setUsState}
          options={STATE_AND_TERRITORY_OPTIONS}
          placeholder="Select State/US Territory:"
          value={usState}
          valueProp="value"
        />
      </SelectContainer>
    </SelectsContainer>
  );
};

PathwaySelect.propTypes = {
  product: productPropType,
  indication: indicationPropType,
  usState: usStatePropType,
  setIndication: PropTypes.func.isRequired,
  setUsState: PropTypes.func.isRequired,
};

export default PathwaySelect;
