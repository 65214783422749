export const COPY = [
  {
    title: `Indications`,
    eyleaHD: `EYLEA® HD (aflibercept) Injection 8 mg is indicated for the treatment of patients with Neovascular (Wet) Age-Related Macular Degeneration (AMD), Diabetic Macular Edema (DME), and Diabetic Retinopathy (DR).`,
    eylea: `EYLEA® (aflibercept) Injection 2 mg is indicated for the treatment of patients with Neovascular (Wet) Age-Related Macular Degeneration (AMD), Macular Edema following Retinal Vein Occlusion (RVO), Diabetic Macular Edema (DME), and Diabetic Retinopathy (DR).`,
  },
];

export const ISI_COPY_SIDEBAR_MARKDOWN = `
## Indications and Usage

LIBTAYO in combination with platinum‐based chemotherapy is indicated for the first‐line treatment
of adult patients with non-small cell lung cancer (NSCLC) with no EGFR, ALK or ROS1 aberrations
and is locally advanced where patients are not candidates for surgical resection or definitive
chemoradiation OR metastatic.

LIBTAYO as a single agent is indicated for the first-line treatment of adult patients with NSCLC
whose tumors have high PD-L1 expression (tumor proportion score [TPS] ≥50%) as determined by an
FDA-approved test, with no EGFR, ALK or ROS1 aberrations, and is locally advanced where patients
are not candidates for surgical resection or definitive chemoradiation OR metastatic.

LIBTAYO is indicated for the treatment of patients with metastatic cutaneous squamous cell carcinoma
(mCSCC) or locally advanced CSCC (laCSCC) who are not candidates for curative surgery or curative
radiation.

LIBTAYO is indicated for the treatment of patients with locally advanced or metastatic basal cell
carcinoma (laBCC or mBCC) who have been previously treated with a hedgehog pathway inhibitor or
for whom a hedgehog pathway inhibitor is not appropriate.
`;

export const ISI_COPY_MAIN_MARKDOWN = `
## Important Safety Information

### Warnings and Precautions

#### Severe and Fatal Immune-Mediated Adverse Reactions

Immune-mediated adverse reactions, which may be severe or fatal, can occur in any organ system or
tissue at any time after starting treatment. While immune-mediated adverse reactions usually occur
during treatment, they can also occur after discontinuation. Immune-mediated adverse reactions affecting
more than one body system can occur simultaneously. Early identification and management are essential
to ensuring safe use of PD-1/PD-L1–blocking antibodies. The definition of immune-mediated adverse
reactions included the required use of systemic corticosteroids or other immunosuppressants and the
absence of a clear alternate etiology. Monitor closely for symptoms and signs that may be clinical
manifestations of underlying immune-mediated adverse reactions. Evaluate liver enzymes, creatinine,
and thyroid function at baseline and periodically during treatment. In cases of suspected immune-mediated
adverse reactions, initiate appropriate workup to exclude alternative etiologies, including infection.
Institute medical management promptly, including specialty consultation as appropriate.

Withhold or permanently discontinue LIBTAYO depending on severity of the adverse reaction (see Section 2
Dosage and Administration in the accompanying Full Prescribing Information). In general, if LIBTAYO
requires interruption or discontinuation, administer systemic corticosteroid therapy (1 to 2 mg/kg/day
prednisone or equivalent) until improvement to Grade 1 or less. Upon improvement to Grade 1 or less,
initiate corticosteroid taper and continue to taper over at least 1 month. Consider administration of
other systemic immunosuppressants in patients whose immune-mediated adverse reactions are not controlled
with corticosteroids. Toxicity management guidelines for adverse reactions that do not necessarily require
systemic steroids (e.g., endocrinopathies and dermatologic reactions) are discussed below.

The incidence and severity of immune-mediated adverse reactions were similar when LIBTAYO was administered
as a single agent or in combination with chemotherapy.

**Immune-mediated pneumonitis:** LIBTAYO can cause immune-mediated pneumonitis. In patients treated with
  other PD-1/PD-L1–blocking antibodies, the incidence of pneumonitis is higher in patients who have received
prior thoracic radiation. Immune-mediated pneumonitis occurred in 2.6% (33/1281) of patients receiving
LIBTAYO, including Grade 4 (0.3%), Grade 3 (0.6%), and Grade 2 (1.6%). Pneumonitis led to permanent
discontinuation in 1.3% of patients and withholding of LIBTAYO in 1.4% of patients. Systemic corticosteroids
were required in all patients with pneumonitis. Pneumonitis resolved in 61% of the 33 patients. Of the
18 patients in whom LIBTAYO was withheld, 10 reinitiated after symptom improvement; of these, 4/10 (40%)
had recurrence of pneumonitis.

**Immune-mediated colitis:** LIBTAYO can cause immune-mediated colitis. The primary component of immune-mediated
colitis was diarrhea. Cytomegalovirus (CMV) infection/reactivation has been reported in patients with
corticosteroid-refractory immune-mediated colitis treated with PD-1/PD-L1–blocking antibodies. In cases of
corticosteroid-refractory immune-mediated colitis, consider repeating infectious workup to exclude alternative
etiologies. Immune-mediated colitis occurred in 2% (25/1281) of patients receiving LIBTAYO, including Grade 3
(0.8%) and Grade 2 (0.9%). Colitis led to permanent discontinuation in 0.4% of patients and withholding of
LIBTAYO in 1.2% of patients. Systemic corticosteroids were required in all patients with colitis. Colitis
resolved in 56% of the 25 patients. Of the 16 patients in whom LIBTAYO was withheld, 6 reinitiated LIBTAYO
after symptom improvement; of these, 4/6 (67%) had recurrence.

**Immune-mediated hepatitis:** LIBTAYO can cause immune-mediated hepatitis. Immune-mediated hepatitis occurred
in 2.4% (31/1281) of patients receiving LIBTAYO, including fatal (<0.1%), Grade 4 (0.3%), Grade 3 (1.6%),
and Grade 2 (0.2%). Hepatitis led to permanent discontinuation of LIBTAYO in 1.4% of patients and withholding
of LIBTAYO in 0.7% of patients. Systemic corticosteroids were required in all patients with hepatitis.
Additional immunosuppression with mycophenolate was required in 13% (4/31) of these patients. Hepatitis
resolved in 39% of the 31 patients. Of the 9 patients in whom LIBTAYO was withheld, 5 reinitiated LIBTAYO
after symptom improvement; of these, 1/5 (20%) had recurrence.

**Immune-mediated endocrinopathies:**
* **Adrenal insufficiency:** LIBTAYO can cause primary or secondary adrenal insufficiency. For Grade 2 or
  higher adrenal insufficiency, initiate symptomatic treatment, including hormone replacement as clinically
  indicated. Withhold LIBTAYO depending on severity. Adrenal insufficiency occurred in 0.5% (6/1281)
  of patients receiving LIBTAYO, including Grade 3 (0.5%). Adrenal insufficiency led to permanent
  discontinuation of LIBTAYO in 1 (<0.1%) patient. LIBTAYO was withheld in 1 (<0.1%) patient due
  to adrenal insufficiency and not reinitiated. Systemic corticosteroids were required in 83% (5/6)
  patients with adrenal insufficiency; of these, the majority remained on systemic corticosteroids.
  Adrenal insufficiency had resolved in 17% of the 6 patients
* **Hypophysitis:** LIBTAYO can cause immune-mediated hypophysitis. Hypophysitis can present with acute
  symptoms associated with mass effect such as headache, photophobia, or visual field defects.
  Hypophysitis can cause hypopituitarism. Initiate hormone replacement as clinically indicated.
  Withhold or permanently discontinue depending on severity. Hypophysitis occurred in 0.5%
  (7/1281) of patients receiving LIBTAYO, including Grade 3 (0.2%) and Grade 2 (0.3%) adverse
  reactions. Hypophysitis led to permanent discontinuation of LIBTAYO in 1 (<0.1%) patient and
  withholding of LIBTAYO in 2 (0.2%) patients. Systemic corticosteroids were required in 86% (6/7)
  of patients with hypophysitis. Hypophysitis resolved in 14% of the 7 patients. Of the 2
  patients in whom LIBTAYO was withheld for hypophysitis, none of the patients reinitiated
* **Thyroid disorders:** LIBTAYO can cause immune-mediated thyroid disorders. Thyroiditis can present
  with or without endocrinopathy. Hypothyroidism can follow hyperthyroidism. Initiate hormone
  replacement or medical management of hyperthyroidism as clinically indicated. Withhold or
  permanently discontinue LIBTAYO depending on severity
* **Thyroiditis:** Thyroiditis occurred in 0.6% (8/1281) of patients receiving LIBTAYO, including
  Grade 2 (0.3%) adverse reactions. No patient discontinued LIBTAYO due to thyroiditis.
  Thyroiditis led to withholding of LIBTAYO in 1 (<0.1%) patient. Systemic corticosteroids
  were not required in any patient with thyroiditis. Thyroiditis resolved in 13% of the
  8 patients. Blood thyroid stimulating hormone increased and blood thyroid stimulating
  hormone decreased have also been reported
* **Hyperthyroidism:** Hyperthyroidism occurred in 3% (39/1281) of patients receiving LIBTAYO,
  including Grade 3 (<0.1%) and Grade 2 (0.9%). No patient discontinued treatment and LIBTAYO
  was withheld in 7 (0.5%) patients due to hyperthyroidism. Systemic corticosteroids were
  required in 8% (3/39) of patients. Hyperthyroidism resolved in 56% of 39 patients. Of
  the 7 patients in whom LIBTAYO was withheld for hyperthyroidism, 2 patients reinitiated
  LIBTAYO after symptom improvement; of these, none had recurrence of hyperthyroidism
* **Hypothyroidism:** Hypothyroidism occurred in 7% (87/1281) of patients receiving LIBTAYO,
  including Grade 3 (<0.1%) and Grade 2 (6%). Hypothyroidism led to permanent
  discontinuation of LIBTAYO in 3 (0.2%) patients. Hypothyroidism led to withholding
  of LIBTAYO in 9 (0.7%) patients. Systemic corticosteroids were required in 1.1%
  (1/87) of patients with hypothyroidism. Hypothyroidism resolved in 6% of the 87
  patients. Majority of the patients with hypothyroidism required long-term thyroid
  hormone replacement. Of the 9 patients in whom LIBTAYO was withheld for hypothyroidism,
  1 reinitiated LIBTAYO after symptom improvement and did not have recurrence of
  hypothyroidism
* **Type 1 diabetes mellitus, which can present with diabetic ketoacidosis:** Monitor for hyperglycemia
  or other signs and symptoms of diabetes. Initiate treatment with insulin as clinically indicated.
  Withhold LIBTAYO depending on severity. Type 1 diabetes mellitus occurred in <0.1% (1/1281) of patients
  (Grade 4). No patient discontinued treatment due to Type 1 diabetes mellitus. Type 1 diabetes mellitus
  led to withholding of LIBTAYO in 0.1% of patients, treatment was reinitiated after symptom improvement.
  Patient received long-term insulin therapy

**Immune-mediated nephritis with renal dysfunction:** LIBTAYO can cause immune-mediated nephritis. Immune-mediated
nephritis occurred in 0.7% (9/1281) of patients receiving LIBTAYO, including fatal (<0.1%), Grade 3
(<0.1%), and Grade 2 (0.5%). Nephritis led to permanent discontinuation in 0.2% of patients and withholding
of LIBTAYO in 0.4% of patients. Systemic corticosteroids were required in all patients with nephritis.
Nephritis resolved in 78% of the 9 patients. Of the 5 patients in whom LIBTAYO was withheld, 4 reinitiated
LIBTAYO after symptom improvement; of these, 1/4 (25%) had recurrence.

**Immune-mediated dermatologic adverse reactions:** LIBTAYO can cause immune-mediated rash or dermatitis.
Exfoliative dermatitis, including Stevens-Johnson syndrome (SJS), toxic epidermal necrolysis (TEN),
and drug rash with eosinophilia and systemic symptoms (DRESS) has occurred with PD-1/PD-L1–blocking antibodies.
Immune-mediated dermatologic adverse reactions occurred in 1.9% (24/1281) of patients receiving LIBTAYO,
including Grade 3 (0.9%) and Grade 2 (0.8%). Immune-mediated dermatologic adverse reactions led to permanent
discontinuation in 0.2% of patients and withholding of LIBTAYO in 1.3% of patients. Systemic corticosteroids
were required in all patients with immune-mediated dermatologic adverse reactions. Immune-mediated dermatologic
adverse reactions resolved in 71% of the 24 patients. Of the 17 patients in whom LIBTAYO was withheld for
dermatologic adverse reaction, 13 reinitiated LIBTAYO after symptom improvement; of these, 5/13 (38%) had
recurrence of the dermatologic adverse reaction. Topical emollients and/or topical corticosteroids may be
adequate to treat mild to moderate non-exfoliative rashes.

**Other immune-mediated adverse reactions:** The following clinically significant immune-mediated adverse reactions
occurred at an incidence of <1% in 1281 patients who received LIBTAYO or were reported with the use of other
PD-1/PD-L1–blocking antibodies. Severe or fatal cases have been reported for some of these adverse reactions.

* **Cardiac/vascular:** Myocarditis, pericarditis, and vasculitis. Permanently discontinue for Grades 2, 3, or 4 myocarditis
* **Nervous system:** Meningitis, encephalitis, myelitis and demyelination, myasthenic syndrome/myasthenia gravis
  (including exacerbation), Guillain-Barré syndrome, nerve paresis, and autoimmune neuropathy
* **Ocular:** Uveitis, iritis, and other ocular inflammatory toxicities. Some cases can be associated with retinal
  detachment. Various grades of visual impairment to include blindness can occur. If uveitis occurs in combination
  with other immune-mediated adverse reactions, consider a Vogt-Koyanagi-Harada–like syndrome, as this may require
  treatment with systemic steroids to reduce the risk of permanent vision loss
* **Gastrointestinal:** Pancreatitis to include increases in serum amylase and lipase levels, gastritis, duodenitis, stomatitis
* **Musculoskeletal and connective tissue:** Myositis/polymyositis/dermatomyositis, rhabdomyolysis, and associated
  sequelae including renal failure, arthritis, polymyalgia rheumatica
* **Endocrine:** Hypoparathyroidism
* **Other (hematologic/immune):** Hemolytic anemia, aplastic anemia, hemophagocytic lymphohistiocytosis (HLH), systemic
  inflammatory response syndrome, histiocytic necrotizing lymphadenitis (Kikuchi lymphadenitis), sarcoidosis, immune
  thrombocytopenia, solid organ transplant rejection, other transplant (including corneal graft) rejection

#### Infusion-Related Reactions

Severe or life-threatening infusion-related reactions occurred in 0.2% of patients receiving LIBTAYO as a single agent.
Monitor patients for signs and symptoms of infusion-related reactions. Common symptoms of infusion-related reaction
include nausea, pyrexia, and vomiting. Interrupt or slow the rate of infusion or permanently discontinue LIBTAYO
based on severity of reaction.

#### Complications of Allogeneic HSCT

Fatal and other serious complications can occur in patients who receive allogeneic hematopoietic stem cell
transplantation (HSCT) before or after being treated with a PD-1/PD-L1–blocking antibody. Transplant-related
complications include hyperacute graft-versus-host disease (GVHD), acute GVHD, chronic GVHD, hepatic
veno-occlusive disease (VOD) after reduced intensity conditioning, and steroid-requiring febrile syndrome
(without an identified infectious cause). These complications may occur despite intervening therapy between
PD-1/PD-L1 blockade and allogeneic HSCT. Follow patients closely for evidence of transplant-related complications
and intervene promptly. Consider the benefit versus risks of treatment with a PD-1/PD-L1–blocking antibody
prior to or after an allogeneic HSCT.

#### Embryo-Fetal Toxicity

LIBTAYO can cause fetal harm when administered to a pregnant woman due to an increased risk of immune-mediated
rejection of the developing fetus resulting in fetal death. Advise women of the potential risk to a fetus.
Advise females of reproductive potential to use effective contraception during treatment with LIBTAYO and for
at least 4 months after the last dose.

#### Adverse Reactions

_LIBTAYO as a single agent:_ the most common adverse
reactions (≥15%) are fatigue, musculoskeletal pain, rash,
diarrhea, and anemia

_LIBTAYO in combination with platinum-based chemotherapy:_
the most common adverse reactions (≥15%) are alopecia,
musculoskeletal pain, nausea, fatigue, peripheral neuropathy, and
decreased appetite

#### Use in Specific Populations

* **Lactation:** Because of the potential for serious adverse reactions in breastfed children, advise women not to breastfeed during treatment and for at least 4 months after the last dose of LIBTAYO
* **Females and males of reproductive potential:** Verify pregnancy status in females of reproductive potential prior to initiating LIBTAYO
`;
