import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFirebaseContext } from '../providers/FirebaseProvider';

import Link from '../components/Link';
import PageHeading from '../components/PageHeading';
import PageWrapper from '../components/PageWrapper';
import { bold } from '../styles/typography';

const GUIDE_FILE = 'Libtayo_Payer_Policy_Portal_User_Guide.pdf';

const ContentWrapper = styled.div`
  width: 100%;
`;

const BodyText = styled.p`
  margin: 0px 30px;
`;
const BoldText = styled.p`
  ${bold}
`;
const StyledLink = styled(Link)`
  text-decoration: underline;
  ${bold}
`;

const UserGuide = () => {
  const firebase = useFirebaseContext();
  const [url, setUrl] = useState('');

  useEffect(() => {
    const storageRef = firebase.storage.ref();
    const guideRef = storageRef.child(`UserGuide/${GUIDE_FILE}`);
    guideRef.getDownloadURL().then((url) => {
      setUrl(url);
    });
  });

  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeading>User Guide</PageHeading>
        <BodyText>
          <StyledLink href={url} download={GUIDE_FILE}>
            Click here
          </StyledLink>{' '}
          to download the User Guide for the LIBTAYO Payer Policy Portal. For
          additional questions, please contact your{' '}
          <BoldText>Oncology Reimbursement Manager&#46;</BoldText>
        </BodyText>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default UserGuide;
