import PropTypes from 'prop-types';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import Link from './Link';
import FooterText from './FooterText';
import PageFooterDisclaimerText from './PageFooterDisclaimerText';
import { siteMetadata, isiMetadata } from '../constants/siteMetadata';
import * as colors from '../styles/colors';
import { ReactComponent as LogoSvg } from '../assets/images/regeneron_logo.svg';

const FOOTER_LINKS = {
  PRIVACY_POLICY: 'https://www.regeneron.com/privacy-policy',
  TERMS_OF_USE: 'https://www.regeneron.com/terms',
  CONTACT_US: 'https://www.regeneron.com/contact',
  CONSUMER_HEALTH:
    'https://www.regeneron.com/downloads/consumer-health-data-privacy-policy.pdf',
};

const FooterMetadata = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  & > div {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    & > * {
      text-align: center;
      margin-left: 16px;
      margin-top: 8px;
    }
  }
`;
const FooterLink = styled(Link).attrs({
  rel: 'noreferrer',
  target: '_blank',
})`
  text-transform: uppercase;
`;
const PrivacyPreferenceCenter = styled(FooterLink)`
  text-transform: uppercase;
  #ot-sdk-btn {
    font-family: 'Barlow-Bold' !important;
    color: #ffffff !important;
    border: none !important;
    height: auto;
    white-space: normal;
    word-wrap: break-word;
    padding: 0 !important;
    font-size: 14px !important;
    line-height: 1.6 !important;
    cursor: pointer;
    -moz-transition: 0.1s ease;
    -o-transition: 0.1s ease;
    -webkit-transition: 1s ease;
    transition: 0.1s ease;
  }
  #ot-sdk-btn.ot-sdk-show-settings:hover,
  #ot-sdk-btn.optanon-show-settings:hover {
    color: #fff;
    background-color: transparent !important;
  }
`;

const RegeneronLogo = styled(LogoSvg)`
  width: 115px;
  path {
    fill: ${({ isISI }) => (isISI ? colors.blankWhite : colors.black)};
  }
`;
const PageFooterWrapper = styled.div`
  color: ${({ theme, isISI }) => (isISI ? theme.text : colors.black)};
  display: flex;
  flex-direction: column;
  & ${RegeneronLogo} {
    color: ${({ theme }) => theme.foreground};
    margin-top: 16px;
  }
  & > * {
    margin: 6px 0;
  }
`;

const PageFooter = ({ inverted, isISI, ...rest }) => {
  const { path } = useRouteMatch();
  const { jobNumber, lastUpdated } = isISI
    ? isiMetadata
    : siteMetadata[path] || {};

  const openPrivacyCenter = () => {
    const btn = document.querySelector('#ot-sdk-btn-floating button');
    btn.click();
  };
  return (
    <PageFooterWrapper inverted={inverted} isISI={isISI} {...rest}>
      {!isISI && <PageFooterDisclaimerText />}
      <FooterText>
        <div>
          <RegeneronLogo isISI={isISI} />
          <div>
            &copy; {new Date().getFullYear()} Regeneron Pharmaceuticals, Inc.
            All rights reserved.
          </div>
          <div>
            <span>{jobNumber}</span>
            <span>&nbsp;</span>
            <span>{lastUpdated}</span>
          </div>
          <div>
            Questions or comments? Call 1.877.734.6777 to contact Regeneron
            Pharmaceuticals, Inc.
          </div>
          <div>777 Old Saw Mill Road, Tarrytown, NY 10591</div>
        </div>
        <FooterMetadata>
          <div>
            <FooterLink href={FOOTER_LINKS.CONSUMER_HEALTH}>
              Consumer Health Data Privacy Policy
            </FooterLink>
            <PrivacyPreferenceCenter
              className="ot-sdk-show-settings"
              onClick={openPrivacyCenter}
            >
              Privacy Preferences Center
            </PrivacyPreferenceCenter>
            <FooterLink href={FOOTER_LINKS.PRIVACY_POLICY}>
              Privacy Policy
            </FooterLink>
            <FooterLink href={FOOTER_LINKS.TERMS_OF_USE}>
              Terms of Use
            </FooterLink>
            <FooterLink href={FOOTER_LINKS.CONTACT_US}>Contact Us</FooterLink>
          </div>
        </FooterMetadata>
      </FooterText>
    </PageFooterWrapper>
  );
};

PageFooter.propTypes = {
  inverted: PropTypes.bool,
  isISI: PropTypes.bool,
};

export default PageFooter;
