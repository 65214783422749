import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FullWidthWrapper from '../FullWidthWrapper';
import { ListHeaderRow, ListBodyRow, List } from '../List';
import PathwayStatusListRow from './PathwayStatusListRow';
import FilterableListTitleBar from '../FilterableListTitleBar';
import SortableListTitleColumn from '../SortableListTitleColumn';
import LoadingSpinner from '../LoadingSpinner';
import { usStatePropType, pathwayStatusPropType } from '../../propTypes';
import { getProductById, sortRecords } from '../../helpers';
import useTableFilters from '../../hooks/useTableFilters';
import useTableSort from '../../hooks/useTableSort';
import {
  filterPathwayStatusesByUserFilters,
  isPathwayStatusVisibleForUsState,
  isPathwayStatusVisibleForIndication,
} from './helpers';
import * as colors from '../../styles/colors';

import { FIELDS, DATA_COLUMN_MARGIN_LEFT } from './constants';

const PathwayStatusSortableListTitleColumn = styled(SortableListTitleColumn)`
  margin-left: ${DATA_COLUMN_MARGIN_LEFT};
`;

const PathwayStatusList = ({
  loading,
  pathwayStatuses,
  productId,
  indicationId,
  usState,
  noResultsText,
}) => {
  const { getSortForField, order, setSort, sortBy } =
    useTableSort(pathwayStatuses);
  const { clearAllFilters, filters, setFilterForField } = useTableFilters();

  const filteredPathwayStatuses = useMemo(() =>
    sortRecords(
      filterPathwayStatusesByUserFilters(pathwayStatuses, filters)
        .filter((pathwayStatus) =>
          isPathwayStatusVisibleForUsState(pathwayStatus, usState),
        )
        .filter((pathwayStatus) =>
          isPathwayStatusVisibleForIndication(pathwayStatus, indicationId),
        ),
      sortBy,
      order,
    ),
  );

  const product = getProductById(productId);

  const hasResults = filteredPathwayStatuses.length > 0;

  const isFiltered = useMemo(() => {
    return Object.values(filters).some(Boolean);
  }, [filters]);

  const renderRows = () =>
    filteredPathwayStatuses.map((pathwayStatus) => (
      <PathwayStatusListRow
        key={pathwayStatus.id}
        pathwayStatus={pathwayStatus}
      />
    ));

  const renderNoResultsText = () => (
    <ListBodyRow>
      <div>{noResultsText}</div>
    </ListBodyRow>
  );

  return (
    <FullWidthWrapper>
      <FilterableListTitleBar
        backgroundColor={colors.darkBlue}
        indicatorColor={product?.color}
        title={product.expandedLabel}
        isListFiltered={isFiltered}
        onClearFilters={clearAllFilters}
        filterFields={FIELDS}
        filters={filters}
        onSetFilter={setFilterForField}
      />

      {loading && <LoadingSpinner size={100} />}
      {!loading && (
        <List>
          <ListHeaderRow>
            {FIELDS.map(({ columnWidth, label, value }) => (
              <PathwayStatusSortableListTitleColumn
                key={value}
                onClick={() => setSort(value)}
                order={getSortForField(value)}
                text={label}
                width={columnWidth}
              />
            ))}
          </ListHeaderRow>

          {hasResults ? renderRows() : renderNoResultsText()}
        </List>
      )}
    </FullWidthWrapper>
  );
};

PathwayStatusList.propTypes = {
  loading: PropTypes.bool.isRequired,
  pathwayStatuses: PropTypes.arrayOf(pathwayStatusPropType),
  productId: PropTypes.number.isRequired,
  indicationId: PropTypes.number.isRequired,
  usState: usStatePropType,
  noResultsText: PropTypes.string.isRequired,
};

export default PathwayStatusList;
