import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import {
  AppHeaderWrapper,
  IconWrapper,
  LogoWrapper,
  NavBar,
  PrescribingInformationContainer,
  SignOutLink,
  StyledLink,
  ToolbarsWrapper,
  UserBar,
  UsernameWrapper,
} from './AppHeader.components';
import AssistanceLink from './AssistanceLink';
import SettingsTooltip from './SettingsTooltip';
import * as Icons from '../Icons';
import { useUserApi } from '../../api/user';
import eyleaLogo from '../../assets/images/LibtayoLogo.png';
import routes from '../../constants/routes';
import * as roles from '../../constants/roles';
import { getCoverageTitle } from '../../helpers';
import { useAppContext } from '../../providers/AppProvider';
import { useAuthContext } from '../../providers/AuthProvider';
import { darkTheme } from '../../styles/themes';
import PrescribingInformation from './PrescribingInformation';

const AppHeader = () => {
  const history = useHistory();
  const { GetUsersFromPractice } = useUserApi();
  const { user, signOut } = useAuthContext();
  const { practice } = useAppContext();
  const settingsIconRef = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [superUsers, setSuperUsers] = useState([]);

  useEffect(() => {
    if (!practice?.id) return;
    const fetchSuperUsers = async () => {
      const allUsers = await GetUsersFromPractice(practice.id);
      const filteredUsers = allUsers.filter((u) => u.role === roles.SUPER_USER);
      setSuperUsers(filteredUsers);
    };
    fetchSuperUsers();
  }, [practice?.id]);

  const handleLogOut = async () => {
    await signOut();
    history.push(routes.login);
  };

  const handleOpenSettings = () => {
    if (user.role === roles.GENERAL_USER) {
      setIsTooltipOpen(!isTooltipOpen);
    } else {
      history.push(routes.admin);
    }
  };

  return (
    <AppHeaderWrapper>
      <LogoWrapper>
        <Link to={routes.home}>
          <img alt="EYLEA" src={eyleaLogo} />
        </Link>
      </LogoWrapper>
      <ToolbarsWrapper>
        <UserBar>
          <PrescribingInformationContainer>
            <PrescribingInformation />
          </PrescribingInformationContainer>
          {user && (
            <>
              <UsernameWrapper>
                {Icons.User} {user.displayName || user.email}
              </UsernameWrapper>
              <SignOutLink onClick={handleLogOut}>LOG OUT</SignOutLink>
              <IconWrapper ref={settingsIconRef} onClick={handleOpenSettings}>
                {Icons.Cog}
              </IconWrapper>
            </>
          )}
        </UserBar>
        <ThemeProvider theme={darkTheme}>
          <NavBar>
            {user && (
              <>
                <StyledLink to={routes.home}>Home</StyledLink>
                <StyledLink to={routes.findCoverage}>
                  {getCoverageTitle(user.role)}
                </StyledLink>

                <StyledLink to={routes.findPolicy}>
                  Find a Medical Policy
                </StyledLink>
                <StyledLink to={routes.findPriorAuth}>
                  Find a Prior Authorization Form
                </StyledLink>
                <StyledLink to={routes.pathwayStatus}>
                  Pathway Status
                </StyledLink>
                <StyledLink to={routes.userGuide}>User Guide</StyledLink>
                <StyledLink to={routes.resources}>Resources</StyledLink>
              </>
            )}
          </NavBar>
        </ThemeProvider>
        <UserBar>
          <AssistanceLink superUsers={superUsers} />
        </UserBar>
      </ToolbarsWrapper>
      <SettingsTooltip
        anchorEl={settingsIconRef}
        isOpen={isTooltipOpen}
        onClose={() => setIsTooltipOpen(false)}
        superUsers={superUsers}
      />
    </AppHeaderWrapper>
  );
};

export default AppHeader;
