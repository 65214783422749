import StateData from 'react-usa-map/src/data/usa-map-dimensions';
import * as colors from '../styles/colors';
import { FIELDS } from '../components/PolicyList/PolicyList.constants';

export * as regex from './regex';

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const INDICATION_IDS = {
  ADVANCED_NSCLC_CHEMOTHERAPY: 434,
  ADVANCED_NSCLC_MONOTHERAPY: 434999,
  ADVANCED_BCC: 457,
  ADVANCED_CSCC: 449,
};

export const INDICATION_IDS_POLICY_DOWNLOADS = {
  ADVANCED_NSCLC_CHEMOTHERAPY: 434,
  ADVANCED_NSCLC_MONOTHERAPY: 434,
  ADVANCED_BCC: 457,
  ADVANCED_CSCC: 449,
};

export const INDICATION_LABELS = {
  ADVANCED_NSCLC_CHEMOTHERAPY:
    'Advanced NSCLC: LIBTAYO in combination with chemotherapy',
  ADVANCED_NSCLC_MONOTHERAPY: 'Advanced NSCLC: LIBTAYO monotherapy',
  ADVANCED_BCC: 'Advanced BCC',
  ADVANCED_CSCC: 'Advanced CSCC',
};

export const INDICATION_OPTIONS = Object.keys(INDICATION_IDS).map((key) => ({
  value: INDICATION_IDS[key],
  label: INDICATION_LABELS[key],
}));

export const INDICATION_OPTIONS_POLICY_DOWNLOADS = Object.keys(
  INDICATION_IDS_POLICY_DOWNLOADS,
).map((key) => ({
  value: INDICATION_IDS_POLICY_DOWNLOADS[key],
  label: INDICATION_LABELS[key],
}));

export const PATHWAY_STATUS_INDICATION_IDS = {
  ADVANCED_NSCLC_CHEMOTHERAPY: 'chemotherapy',
  ADVANCED_NSCLC_MONOTHERAPY: 'monotherapy',
  ADVANCED_BCC: 'bcc',
  ADVANCED_CSCC: 'cscc',
};

export const DISABLED_PATHWAY_STATUS_INDICATION_IDS = ['bcc', 'cscc'];

export const PATHWAY_STATUS_INDICATION_OPTIONS = Object.keys(
  PATHWAY_STATUS_INDICATION_IDS,
).map((key) => ({
  value: PATHWAY_STATUS_INDICATION_IDS[key],
  label: INDICATION_LABELS[key],
}));

export const PATHWAY_STATUS_POSITION_NOT_REVIEWED = 'Not Reviewed';

export const LIBTAYO_ID = 232993;

export const MEDICATION_OPTIONS = [
  {
    value: LIBTAYO_ID,
    label: 'LIBTAYO',
    expandedLabel: 'LIBTAYO (cemiplimab-rwlc)',
    indications: INDICATION_OPTIONS,
    color: colors.pink,
    fields: FIELDS,
  },
];

export const CHANNEL_OPTIONS = [
  {
    label: 'Commercial',
    value: 1,
  },
  {
    label: 'Medicare',
    value: 2,
  },
  {
    label: 'State Medicaid',
    value: 3,
  },
  {
    label: 'Managed Medicaid',
    value: 5,
  },
  {
    label: 'Health Exchange',
    value: 4,
  },
];

export const TERRITORIES = [
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'North Marianas',
    value: 'MP',
  },
  {
    label: 'US Virgin Islands',
    value: 'VI',
  },
];

const DC = {
  label: 'District of Columbia',
  value: 'DC2',
};

export const STATE_AND_TERRITORY_OPTIONS = Object.values(StateData())
  .map((s) => ({
    label: s.name,
    value: s.abbreviation,
  }))
  .concat(DC)
  .concat(TERRITORIES)
  .sort((a, b) => a.label.localeCompare(b.label));

export const PRESCRIBING_INFORMATION = {
  LIBTAYO: {
    label: 'LIBTAYO Prescribing Information',
    value: 'https://www.regeneron.com/downloads/libtayo_fpi.pdf',
  },
};

export const PRESCRIBING_INFORMATION_LINK =
  'https://www.regeneron.com/downloads/libtayo_fpi.pdf';
