import {
  PATHWAY_STATUS_INDICATION_IDS,
  PATHWAY_STATUS_POSITION_NOT_REVIEWED,
} from '../../constants';

export const filterPathwayStatusesByUserFilters = (
  pathwayStatuses,
  filters,
) => {
  if (!Object.values(filters).some(Boolean)) {
    return pathwayStatuses;
  }

  return Object.keys(filters).reduce(
    (acc, field) =>
      acc.filter((pathwayStatus) =>
        pathwayStatus[field]
          ?.toLowerCase()
          .includes(filters[field]?.toLowerCase()),
      ),
    [...pathwayStatuses],
  );
};

export const isPathwayStatusVisibleForUsState = (pathwayStatus, usState) =>
  pathwayStatus.usState === usState;

export const isPathwayStatusVisibleForIndication = (
  pathwayStatus,
  indicationId,
) => {
  switch (indicationId) {
    case PATHWAY_STATUS_INDICATION_IDS.ADVANCED_NSCLC_CHEMOTHERAPY:
      return (
        pathwayStatus.indications.chemotherapy.position !==
        PATHWAY_STATUS_POSITION_NOT_REVIEWED
      );
    case PATHWAY_STATUS_INDICATION_IDS.ADVANCED_NSCLC_MONOTHERAPY:
      return (
        pathwayStatus.indications.monotherapy.position !==
        PATHWAY_STATUS_POSITION_NOT_REVIEWED
      );
    default:
      return true;
  }
};
