const routes = {
  home: '/home',
  login: '/login',
  findCoverage: '/coverage',
  findPolicy: '/policy',
  findPriorAuth: '/prior-auth',
  userGuide: '/user-guide',
  resources: '/resources',
  admin: '/admin',
  pathwayStatus: '/pathway-status',
};

export default routes;
