import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import * as roles from '../constants/roles';
import { useAuthContext } from '../providers/AuthProvider';

import StripedBackgroundPageWrapper from '../components/StripedBackgroundPageWrapper';
import PageHeading from '../components/PageHeading';
import JumboButton from '../components/JumboButton';
import { darkTheme } from '../styles/themes';
import { ProductText } from '../styles/constants';

const HomeCallToActionWrapper = styled.div`
  margin: 2em 0 4em 0;
  text-align: center;
`;

const HomeCallToAction = ({ onClickButton }) => {
  const { user } = useAuthContext();

  const buttonLabel = {
    [roles.GENERAL_USER]: 'View Available Plans',
    [roles.RBM]: 'Add Plans to Your Favorites',
    [roles.SUPER_USER]: 'Add Plans to Your Practice Portal',
  }[user.role];

  return (
    <StripedBackgroundPageWrapper>
      <HomeCallToActionWrapper>
        <PageHeading>
          Welcome to the Payer Policy Portal for{' '}
          <ProductText>LIBTAYO</ProductText>!
        </PageHeading>
        <ThemeProvider theme={darkTheme}>
          <JumboButton onClick={onClickButton}>{buttonLabel}</JumboButton>
        </ThemeProvider>
      </HomeCallToActionWrapper>
    </StripedBackgroundPageWrapper>
  );
};

HomeCallToAction.propTypes = {
  onClickButton: PropTypes.func.isRequired,
};

export default HomeCallToAction;
