export const DATA_COLUMN_MARGIN_LEFT = '40px';

export const FIELDS = [
  {
    columnWidth: '40%',
    label: 'Payer Participant',
    value: 'payerParticipant',
  },
  {
    columnWidth: '40%',
    label: 'Pathway',
    value: 'pathway',
  },
];
