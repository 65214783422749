import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Icons from './Icons';
import { SortIcon, DataColumn } from './List';

const Wrapper = styled(DataColumn)`
  & > div {
    cursor: pointer;
  }
`;

const SortableListTitleColumn = ({
  onClick,
  order,
  text,
  width,
  className,
}) => {
  return (
    <Wrapper width={width} className={className}>
      {order && (
        <SortIcon onClick={onClick} order={order}>
          {Icons.ChevronUp}
        </SortIcon>
      )}
      <div onClick={onClick}>{text}</div>
    </Wrapper>
  );
};

SortableListTitleColumn.propTypes = {
  onClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc', '']),
  text: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};

export default SortableListTitleColumn;
