import { createGlobalStyle } from 'styled-components';

import arialLight from '../assets/fonts/arial_light.ttf';
import arialRegular from '../assets/fonts/arial_regular.ttf';
import arialBold from '../assets/fonts/arial_bold.ttf';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }
  @font-face {
    font-family: 'Arial Light';
    src: url(${arialLight});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Arial Regular';
    src: url(${arialRegular});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Arial Bold';
    src: url(${arialBold});
    font-weight: normal;
    font-style: normal;
  }

  body {
    margin: 0;
    font-family: 'Arial Regular', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.text};
    height: 100vh;
  }
  // global component styles
  a {
    color: ${({ theme }) => theme.foreground};
    text-decoration: none;
  }
`;

export default GlobalStyle;
