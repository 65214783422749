import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as typography from '../styles/typography';
import FooterText from './FooterText';

const LargerBoldFooterText = styled.div`
  font-size: 1rem;
  ${typography.bold};
  & > div {
    width: 50%;
  }
  & > sup {
    vertical-align: top;
    font-size: 0.6em;
  }
`;

const BoldText = styled.span`
  ${typography.bold};
`;

const LineBreak = styled.div`
  margin: 16px;
`;

const PageFooterDisclaimer = ({ isISI }) => (
  <React.Fragment>
    <LargerBoldFooterText>
      Information contained on this website comes directly from the payer&apos;s
      medical policy, is subject to change, and is not a guarantee of insurance
      coverage. This information is provided for your education only, and it
      should not be construed as medical advice. Patient-specific coverage may
      vary from the medical policy. Refer to the LIBTAYO Surround
      <sup>&reg;</sup> benefits verification or your patient&apos;s plan
      document to verify patient-specific coverage.
    </LargerBoldFooterText>
    {isISI && <LineBreak />}
    <FooterText>
      <BoldText>
        LIBTAYO<sup>®</sup> and LIBTAYO Surround<sup>®</sup> are registered
        trademarks of Regeneron Pharmaceuticals, Inc.
      </BoldText>
    </FooterText>
    {isISI && <LineBreak />}
    <FooterText>
      Other trademarks or registered trademarks are the property of their
      respective owners.
    </FooterText>
  </React.Fragment>
);

PageFooterDisclaimer.propTypes = {
  isISI: PropTypes.bool,
};

export default PageFooterDisclaimer;
