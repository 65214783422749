import { useEffect, useState } from 'react';

import useCurrentCollections from './useCurrentCollections';
import { sortObjectsExcludingUndefined } from '../helpers';
import { useAuthContext } from '../providers/AuthProvider';
import { useFirebaseContext } from '../providers/FirebaseProvider';

const usePolicies = () => {
  const { user } = useAuthContext();
  const firebase = useFirebaseContext();

  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getCurrentPoliciesCollectionName } = useCurrentCollections();

  useEffect(() => {
    if (!user?.uid) return;

    const fetchPolicies = async () => {
      setLoading(true);

      const currentPoliciesCollectionName =
        await getCurrentPoliciesCollectionName();

      if (currentPoliciesCollectionName) {
        const policiesDoc = await firebase.firestore
          .collection('mmitPoliciesCurrent')
          .doc(currentPoliciesCollectionName)
          .collection('currentPolicies')
          .get();
        const allPolicies = policiesDoc.docs.map((d) => d.data());
        const sortedPolicies = sortObjectsExcludingUndefined({
          items: allPolicies,
          sortProp: 'lives',
          sortFn: (a, b) => b.lives - a.lives,
        });
        setPolicies(sortedPolicies);
      } else {
        console.warn(
          'No currentCollections key set for policies. Not loading policies.',
        );
      }

      setLoading(false);
    };
    fetchPolicies();
  }, [firebase, user?.uid]);

  return {
    policies,
    loading,
  };
};

export default usePolicies;
