import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { linkStyles } from './Link';
import * as typography from '../styles/typography';
import * as colors from '../styles/colors';
import * as Icons from './Icons';
import { ListTitleBar, ListTitleHeader, LIST_PADDING_X } from './List';
import ListFilterMenu from './ListFilterMenu';

const StyledTitleBar = styled(ListTitleBar)`
  align-items: stretch;
  margin-bottom: 24px;
  padding: 0px;
  background-color: ${(props) => props.backgroundColor || colors.darkBlue};
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ProductIndicator = styled.div`
  width: ${LIST_PADDING_X};
  height: 100%;
  margin-right: ${LIST_PADDING_X};
  background-color: ${(props) => props.color || 'transparent'};
`;
const FilterControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 8px ${LIST_PADDING_X};
  & > div {
    margin-left: 16px;
  }
`;
const ClearFiltersButton = styled.div`
  ${linkStyles};
  ${typography.bold};
  color: ${colors.white};
`;
const FiltersIcon = styled.div`
  ${typography.caption};
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  & svg {
    stroke: ${(props) => props.active && colors.lightYellow};
  }
`;

const FilterableListTitleBar = ({
  indicatorColor,
  title,
  backgroundColor,
  isListFiltered,
  filterFields,
  filters,
  onClearFilters,
  onSetFilter,
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  return (
    <StyledTitleBar backgroundColor={backgroundColor}>
      <ProductWrapper>
        <ProductIndicator color={indicatorColor} />
        <ListTitleHeader>{title}</ListTitleHeader>
      </ProductWrapper>
      <FilterControlsWrapper>
        {isListFiltered && (
          <ClearFiltersButton
            activeColor={colors.lightYellow}
            onClick={onClearFilters}
          >
            SHOW ALL
          </ClearFiltersButton>
        )}
        <FiltersIcon
          active={isListFiltered}
          onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
        >
          {Icons.Filters}
          FILTER
          {isFilterMenuOpen && (
            <ListFilterMenu
              fields={filterFields}
              activeFilters={filters}
              onClose={() => setIsFilterMenuOpen(false)}
              onClearFilter={onClearFilters}
              onSetFilter={onSetFilter}
            />
          )}
        </FiltersIcon>
      </FilterControlsWrapper>
    </StyledTitleBar>
  );
};

FilterableListTitleBar.propTypes = {
  backgroundColor: PropTypes.string,
  indicatorColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  isListFiltered: PropTypes.bool.isRequired,
  filterFields: PropTypes.array,
  filters: PropTypes.array,
  onClearFilters: PropTypes.func.isRequired,
  onSetFilter: PropTypes.func.isRequired,
};

export default FilterableListTitleBar;
