import styled from 'styled-components';

const FooterText = styled.div`
  font-size: 0.8rem;
  display: flex;
  & > div {
    width: 50%;
  }
`;

export default FooterText;
