import { useState } from 'react';
import { useFirebaseContext } from '../providers/FirebaseProvider';

const usePathwayStatuses = () => {
  const firebase = useFirebaseContext();
  const [pathwayStatuses, setPathwayStatuses] = useState([]);
  const [pathwayStatusSourceDate, setPathwayStatusSourceDate] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPathwayStatuses = async () => {
    setLoading(true);
    const snapshot = await firebase.firestore
      .collection('pathwayStatuses')
      .get();
    setPathwayStatuses(
      snapshot.docs.map((d) => ({
        id: d.id, // Include ID so we can set React iterator keys
        ...d.data(),
      })),
    );
    setLoading(false);
  };

  const fetchPathwayStatusSourceDate = async () => {
    try {
      const snapshot = await firebase.firestore
        .collection('pathwayStatusDateUpdated')
        .doc('dateUpdated')
        .get();
      const dateUpdated = snapshot.data();
      setPathwayStatusSourceDate(dateUpdated);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    fetchPathwayStatuses,
    loading,
    pathwayStatuses,
    fetchPathwayStatusSourceDate,
    pathwayStatusSourceDate,
  };
};

export default usePathwayStatuses;
