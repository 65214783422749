import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import * as colors from '../styles/colors';

import HomeCallToAction from './HomeCallToAction';
import { ListTextButton } from '../components/List';
import PageWrapper from '../components/PageWrapper';
import PageHeading from '../components/PageHeading';
import PolicyList from '../components/PolicyList';
import { useAppContext } from '../providers/AppProvider';
import { useAuthContext } from '../providers/AuthProvider';
import routes from '../constants/routes';
import { SUPER_USER } from '../constants/roles';
import { ProductText } from '../styles/constants';

const NO_RESULTS_TEXT =
  'You currently have no plans marked as favorite.  You can favorite any plan in your search results to add them to your homepage.';

const StyledPageHeading = styled(PageHeading)`
  margin-bottom: 10px;
  text-align: center;
`;

const Subheading = styled.div`
  text-align: center;
  color: ${colors.greyscale[600]};
  margin-bottom: 2rem;
  a {
    color: ${colors.darkBlue};
  }
`;

const TableControlsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  width: 100%;
`;

const ViewPlansLink = styled.a`
  text-decoration: underline;
`;

const Home = () => {
  const history = useHistory();
  const { firstTimeExperience, isInitializing, isLoading, policies, practice } =
    useAppContext();
  const { user } = useAuthContext();
  const [viewAllPlans, setViewAllPlans] = useState(true);

  if (isInitializing) return null;
  if (firstTimeExperience) {
    return (
      <HomeCallToAction
        onClickButton={() => {
          history.push(routes.findCoverage);
        }}
      />
    );
  } else {
    const showVisibleToPractice =
      user.role === SUPER_USER && !practice?.allPoliciesVisible;
    return (
      <PageWrapper>
        <StyledPageHeading>
          Welcome to the Payer Policy Portal for{' '}
          <ProductText>LIBTAYO</ProductText>!
        </StyledPageHeading>
        {viewAllPlans ? (
          <Subheading>
            <ViewPlansLink
              href="#"
              onClick={() => setViewAllPlans(!viewAllPlans)}
            >
              View all your saved FAVORITES
            </ViewPlansLink>{' '}
            | Below Are All Plans Selected for Practice
          </Subheading>
        ) : (
          <Subheading>
            Below are your saved FAVORITES |{' '}
            <ViewPlansLink
              href="#"
              onClick={() => setViewAllPlans(!viewAllPlans)}
            >
              View All Plans Selected for Practice
            </ViewPlansLink>
          </Subheading>
        )}
        <TableControlsRow>
          <ListTextButton to={routes.findCoverage}>New Query</ListTextButton>
        </TableControlsRow>
        <PolicyList
          titleBarBackgroundColor={colors.yellowGreen}
          loading={isLoading}
          title={viewAllPlans ? '' : 'Favorites'}
          policies={viewAllPlans ? policies.visible : policies.favorites}
          noResultsText={NO_RESULTS_TEXT}
          showMeta
          showVisibleToPractice={showVisibleToPractice}
        />
      </PageWrapper>
    );
  }
};

export default Home;
