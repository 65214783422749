import { useState } from 'react';

import useCurrentCollections from './useCurrentCollections';
import { useFirebaseContext } from '../providers/FirebaseProvider';

const groupPlansByMedicalGroup = (plans) => {
  const medicalGroupsMap = plans.reduce((acc, plan) => {
    if (acc[plan.medicalGroupId]) return acc;
    acc[plan.medicalGroupId] = {
      name: plan.medicalGroupName,
      id: plan.medicalGroupId,
      formularyId: plan.formularyId,
    };
    return acc;
  }, {});
  return Object.values(medicalGroupsMap);
};

const usePlans = () => {
  const firebase = useFirebaseContext();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getCurrentPlansCollectionName } = useCurrentCollections();

  const fetchPlans = async () => {
    setLoading(true);

    const currentPlansCollectionName = await getCurrentPlansCollectionName();

    if (currentPlansCollectionName) {
      const plansDoc = await firebase.firestore
        .collection('mmitPlansCurrent')
        .doc(currentPlansCollectionName)
        .collection('currentPlans')
        .get();
      const allPlans = plansDoc.docs.map((d) => d.data());
      const groupedPlans = groupPlansByMedicalGroup(allPlans);
      setPlans(groupedPlans);
    } else {
      console.warn(
        'No currentCollections key set for plans. Not loading plans.',
      );
    }

    setLoading(false);
  };

  return {
    fetchPlans,
    loading,
    plans,
  };
};

export default usePlans;
