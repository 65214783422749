import { useFirebaseContext } from '../providers/FirebaseProvider';
import { useUserApi } from './user';

export const usePracticeApi = () => {
  const firebase = useFirebaseContext();
  const { GetUsersFromIds, GetUsersFromPractice } = useUserApi();

  const CreatePractice = async (practice, rbmId) => {
    try {
      await firebase.firestore
        .collection('practices')
        .doc()
        .set(
          {
            allPoliciesVisible: true,
            policies: [],
            practiceDetails: {
              displayName: practice.displayName,
              address1: practice.address1,
              address2: practice.address2,
              city: practice.city,
              usState: practice.usState,
              zip: practice.zip,
              phone: practice.phone,
            },
            rbm: rbmId,
          },
          { merge: true },
        );
    } catch (error) {
      console.log(error);
    }
  };

  const GetPracticeById = async (practiceId) => {
    const doc = await firebase.firestore
      .collection('practices')
      .doc(practiceId)
      .get();
    const practice = doc.data();
    practice.id = practiceId;
    if (practice.rbm) {
      const rbm = await firebase.firestore
        .collection('users')
        .doc(practice?.rbm)
        .get();
      const { displayName, email } = rbm.data();
      practice.rbm = {
        displayName,
        email,
        id: practice.rbm,
      };
    }
    return practice;
  };

  const GetPractices = async () => {
    try {
      const getPractices = await firebase.firestore
        .collection('practices')
        .orderBy('practiceDetails.displayName')
        .get();
      const allPlans = getPractices.docs.map((d) => {
        return { ...d.data(), id: d.id };
      });
      return allPlans;
    } catch (error) {
      console.log(error);
    }
  };

  const EditPractice = async (practice) => {
    try {
      await firebase.firestore
        .collection('practices')
        .doc(practice.id)
        .set(
          {
            practiceDetails: {
              displayName: practice.displayName,
              address1: practice.address1,
              address2: practice.address2,
              city: practice.city,
              usState: practice.usState,
              zip: practice.zip,
              phone: practice.phone,
            },
          },
          { merge: true },
        );
    } catch (error) {
      console.log(error);
    }
  };

  const RemovePractice = async (practiceId) => {
    try {
      const usersFromPractice = await GetUsersFromPractice(practiceId);
      usersFromPractice.forEach(async (user) => {
        const deleteUser = firebase.functions.httpsCallable('deleteUserInAuth');
        await deleteUser(user.id).then(() => {
          return firebase.firestore.collection('users').doc(user.id).delete();
        });
      });
      return firebase.firestore
        .collection('practices')
        .doc(practiceId)
        .delete();
    } catch (error) {
      console.log(error);
    }
  };

  const SetAllPoliciesVisibilityForPractice = async (
    practiceId,
    allPoliciesVisible,
  ) => {
    try {
      await firebase.firestore
        .collection('practices')
        .doc(practiceId)
        .set({ allPoliciesVisible }, { merge: true });
    } catch (error) {
      throw error;
    }
  };

  const ListenToPracticeUpdates = (practiceId, onChange) => {
    const unsubscribe = firebase.firestore
      .collection('practices')
      .doc(practiceId)
      .onSnapshot((doc) => {
        const data = doc.data();
        onChange(data);
      });
    return unsubscribe;
  };

  const ListenToPracticeUserUpdates = (practiceId, onChange) => {
    const unsubscribe = firebase.firestore
      .collection('practices')
      .doc(practiceId)
      .onSnapshot(async (doc) => {
        const data = doc.data();
        const userIds = data?.users?.map((u) => u.userId) ?? [];
        const users = await GetUsersFromIds(userIds);
        onChange(users);
      });
    return unsubscribe;
  };

  return {
    CreatePractice,
    GetPracticeById,
    GetPractices,
    EditPractice,
    ListenToPracticeUpdates,
    ListenToPracticeUserUpdates,
    RemovePractice,
    SetAllPoliciesVisibilityForPractice,
  };
};
