import {
  INDICATION_OPTIONS,
  PATHWAY_STATUS_INDICATION_OPTIONS,
  CHANNEL_OPTIONS,
  MEDICATION_OPTIONS,
  STATE_AND_TERRITORY_OPTIONS,
} from '../constants';
import { SUPER_USER } from '../constants/roles';
export { default as sortRecords } from './sortRecords';

export const getProductById = (productId) => {
  if (productId) {
    const option = MEDICATION_OPTIONS.find(({ value }) => value === productId);
    return option ?? {};
  }
  return {};
};

export const getChannelLabelById = (channelId) => {
  if (channelId) {
    const option = CHANNEL_OPTIONS.find(
      ({ label, value }) => value === channelId,
    );
    return option ? option.label : '';
  }
  return '';
};

export const getPathwayStatusIndicationLabelById = (indicationId) => {
  const option = PATHWAY_STATUS_INDICATION_OPTIONS.find(
    ({ label, value }) => value === indicationId,
  );
  return option.label;
};

export const getIndicationLabelById = (indicationId) => {
  const option = INDICATION_OPTIONS.find(
    ({ label, value }) => value === indicationId,
  );
  return option.label;
};

/**
 * Gets the field name associated with the indications for the given product.
 * Note that this method is a holdover from the Eylea application, where there
 * are multiple products and therefore multiple indication fields.
 */
const getIndicationsFieldName = (productId) => {
  return 'indications';
};

const getIndication = (policy, indicationId, productId) => {
  const indicationsFieldName = getIndicationsFieldName(productId);
  return policy[indicationsFieldName].find(
    (i) => i.indicationId === indicationId,
  );
};

export const decoratePolicyWithIndication = (
  policy,
  indicationId,
  productId,
) => ({
  ...policy,
  ...getIndication(policy, indicationId || policy.indicationId, productId),
});

export const getCoverageTitle = (role) =>
  `${role === SUPER_USER ? 'Find' : 'View'} Insurance Coverage`;

const addToStateArray = (setter, arr) => (val) => {
  setter([...arr, val]);
};
const removeFromStateArray = (setter, arr) => (val) => {
  setter(arr.filter((i) => i !== val));
};
const toggleStateArray = (setter, arr) => (val) => {
  if (arr.includes(val)) {
    removeFromStateArray(setter, arr)(val);
  } else {
    addToStateArray(setter, arr)(val);
  }
};

/**
 * Gets the full name of a US state or territory by its abbreviation.
 * Returns the supplied abbreviation if the state or territory cannot be found.
 * @param {String} stateAbbreviation - The abbreviation of the US state or territory
 */
export const getUsStateFullNameFromAbbreviation = (stateAbbreviation) =>
  STATE_AND_TERRITORY_OPTIONS.find(
    ({ label, value }) => value === stateAbbreviation,
  )?.label || stateAbbreviation;

export const createStateArrayUtils = (setter, arr) => ({
  add: addToStateArray(setter, arr),
  toggle: toggleStateArray(setter, arr),
  remove: removeFromStateArray(setter, arr),
});

export const sortObjectsExcludingUndefined = ({ items, sortProp, sortFn }) => {
  const itemsWithDefinedProp = items.filter(
    (i) => typeof i[sortProp] !== 'undefined',
  );
  const itemsWithUndefinedProp = items.filter(
    (i) => typeof i[sortProp] === 'undefined',
  );
  const sortedItems = itemsWithDefinedProp.sort(sortFn);
  const combinedItems = [...sortedItems, ...itemsWithUndefinedProp];
  return combinedItems;
};

/**
 * Trims search input
 * @param {String} val String value to trim
 * @param {Object} config
 * @param {Boolean} config.stripPunctuation should it also remove punctuation from the input?
 */
export const trimSearchInput = (val, { stripPunctuation = false } = {}) => {
  let res = val;
  if (stripPunctuation) res = res.replace(/[^\w ]/g, '');
  return res.trim();
};

/**
 * Gets the product label for a given product ID.
 * Note that this method is a holdover from the Eylea application, where there
 * are multiple products.
 */
export const getProductLabelById = (productId) => 'Libtayo';
