import styled, { css } from 'styled-components';

import { globalTransition } from '../styles/utils';
import * as colors from '../styles/colors';

// export separately so we can easily style Link elements
// from react-router library
export const linkStyles = css`
  color: ${({ disabled, theme }) =>
    disabled ? theme.disabled : theme.foreground};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  transition: ${globalTransition('color')};
  & svg * {
    fill: ${({ theme }) => theme.foreground};
    transition: ${globalTransition('fill')};
  }
  &:hover,
  &:active,
  &.active {
    color: ${({ activeColor, disabled, theme }) =>
      disabled ? theme.disabled : activeColor || colors.pink};
    & svg * {
      fill: ${({ activeColor, disabled, theme }) =>
        disabled ? theme.disabled : activeColor || theme.accent};
    }
  }
`;

const Link = styled.a`
  ${linkStyles};
`;

export default Link;
