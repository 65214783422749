import routes from './routes';

export const siteMetadata = {
  [routes.login]: {
    title: 'LIBTAYO Payer Policy Portal',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: false,
  },
  [`${routes.login}/forgot`]: {
    title: 'LIBTAYO Payer Policy Portal',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: false,
  },

  [routes.home]: {
    title: 'Home',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },

  [routes.findCoverage]: {
    title: 'Find Insurance Coverage',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },
  [`${routes.findCoverage}/results`]: {
    title: 'Results',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },

  [routes.findPolicy]: {
    title: 'Find a Medical Policy',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },

  [routes.findPriorAuth]: {
    title: 'Find a PA Form',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },

  [routes.pathwayStatus]: {
    title: 'Pathway Status',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },
  [`${routes.pathwayStatus}/results`]: {
    title: 'Pathway Status: Results',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },

  [routes.userGuide]: {
    title: 'User Guide',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },

  [routes.resources]: {
    title: 'Resources',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },
  [routes.admin]: {
    title: 'Admin Settings',
    jobNumber: 'US.LIB.24.03.0011',
    lastUpdated: '05/24',
    showBanners: true,
  },
};

export const isiMetadata = {
  title: 'Isi',
  jobNumber: 'US.LIB.24.03.0011',
  lastUpdated: '05/24',
};
