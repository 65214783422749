import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'query-string';

import PageWrapper from '../components/PageWrapper';
import PageHeading from '../components/PageHeading';
import PolicyList from '../components/PolicyList';
import { ListTextButton } from '../components/List';
import { QueryParams, QueryParam } from '../components/QueryParam';
import routes from '../constants/routes';
import * as colors from '../styles/colors';

import { useAppContext } from '../providers/AppProvider';
import { useAuthContext } from '../providers/AuthProvider';
import { SUPER_USER, GENERAL_USER } from '../constants/roles';
import {
  decoratePolicyWithIndication,
  getChannelLabelById,
  getIndicationLabelById,
  getProductById,
  getUsStateFullNameFromAbbreviation,
} from '../helpers';

const StyledPageHeading = styled(PageHeading)`
  margin-bottom: 0;
`;

const PageHeadingSeparator = styled.div`
  &:before {
    content: '|';
  }
  margin: 0 1rem;
`;

const PageHeadingWithLink = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const ViewAllPlansLink = styled(Link)`
  text-decoration: underline;
  color: ${colors.darkBlue};
`;

const TableControlsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const SourceText = styled.div`
  margin-top: 1em;
  width: 100%;
  font-size: 12px;
  margin-left: -16px;
`;

const NO_RESULTS_TEXT = 'Your current search has no corresponding results.';

const CoverageResults = () => {
  const { search } = useLocation();
  const params = qs.parse(search);

  const [filteredPolicies, setFilteredPolicies] = useState([]);
  const productId = parseInt(params.productId);
  const product = getProductById(productId);
  const usState = params.usState;
  const channelId = parseInt(params.channelId);
  const indicationId = parseInt(params.indicationId);

  const { user } = useAuthContext();
  const { isLoading, policies, practice } = useAppContext();
  useEffect(() => {
    const basePolicies =
      user.role !== GENERAL_USER || practice?.allPoliciesVisible
        ? policies.all
        : // visible policies filtered to only those that match the current indication ID
          policies.visible.filter(
            (p) => p.indicationId === parseInt(params.indicationId),
          );
    const mapped = basePolicies.map((p) =>
      decoratePolicyWithIndication(p, parseInt(params.indicationId), productId),
    );

    const filtered = [
      (i) => (usState ? i.states.includes(usState) : i),
      (i) => (channelId ? i.channelId === channelId : i),
      // (i) => (productId ? i.products.includes(productId) : i),
    ].reduce((acc, filterFn) => acc.filter(filterFn), mapped);
    setFilteredPolicies(filtered);
  }, [
    channelId,
    usState,
    params.indicationId,
    policies.all,
    policies.visible,
    practice?.allPoliciesVisible,
    user,
  ]);

  const showVisibleToPractice =
    user.role === SUPER_USER && !practice?.allPoliciesVisible;
  return (
    <PageWrapper>
      <TableControlsRow>
        <ListTextButton to={routes.findCoverage}>New Query</ListTextButton>
      </TableControlsRow>
      <PageHeadingWithLink>
        <StyledPageHeading>Insurance Coverage: Results</StyledPageHeading>
        <PageHeadingSeparator />

        <ViewAllPlansLink to="/home?view=plansSelectedForPractice">
          View All Plans Selected for Practice
        </ViewAllPlansLink>
      </PageHeadingWithLink>

      <QueryParams>
        <QueryParam label="Product" value={product.label} />
        <QueryParam label="Channel" value={getChannelLabelById(channelId)} />
        <QueryParam
          label="Indication"
          value={getIndicationLabelById(indicationId)}
        />
        <QueryParam
          label="State/Territory"
          value={getUsStateFullNameFromAbbreviation(usState)}
        />
      </QueryParams>

      <PolicyList
        loading={isLoading}
        noResultsText={NO_RESULTS_TEXT}
        policies={filteredPolicies}
        showVisibleToPractice={showVisibleToPractice}
        product={product}
        title={product.expandedLabel}
      />
      <SourceText>
        Source: MMIT (Data is updated daily. New policies published within 15
        days.)
      </SourceText>
    </PageWrapper>
  );
};

export default CoverageResults;
