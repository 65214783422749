import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bold } from '../styles/typography';

const QueryParams = styled.dl`
  width: 100%;
`;

const QueryParamLabel = styled.dt`
  ${bold}
  display: inline-block;
  margin-right: 0.25rem;
`;

const QueryParamValue = styled.dd`
  display: inline-block;
  text-transform: uppercase;
  margin: 0;
  margin-right: 1rem;
`;

const QueryParam = ({ label, value }) => (
  <React.Fragment>
    <QueryParamLabel>{label}:</QueryParamLabel>
    <QueryParamValue>{value}</QueryParamValue>
  </React.Fragment>
);

QueryParam.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};

export { QueryParams, QueryParam };
