import PropTypes from 'prop-types';

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]).isRequired;

export const policyIndicationPropType = PropTypes.shape({
  stepCategory: PropTypes.string,
  stepStatement: PropTypes.string,
  duration: PropTypes.string,
  reauthorizationRequired: PropTypes.string,
});

export const policyPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  coverageStatus: PropTypes.string.isRequired,
  indications: PropTypes.arrayOf(policyIndicationPropType).isRequired,
});

export const indicationPropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string,
});

export const selectItemPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const practicePropType = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
});

export const userPropType = PropTypes.shape({
  displayName: PropTypes.string,
  email: PropTypes.string,
  practiceId: PropTypes.string,
  role: PropTypes.string,
  uid: PropTypes.string,
});

export const productPropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string,
  indications: PropTypes.arrayOf(indicationPropType),
});

export const usStatePropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string,
});

export const pathwayStatusPropType = PropTypes.shape({
  usState: PropTypes.string.isRequired,
  payerParticipant: PropTypes.string.isRequired,
  pathway: PropTypes.string,
});
