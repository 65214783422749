import React from 'react';
import PropTypes from 'prop-types';

import * as colors from '../styles/colors';
import ReactSelect, { components } from 'react-select';

export const selectStyles = {
  control: (
    provided,
    { isFocused, hasValue, isDisabled, isPi, selectProps },
  ) => ({
    ...provided,
    cursor: 'pointer',
    borderColor:
      isFocused || hasValue ? colors.darkBlue : colors.greyscale[600],
    borderRadius: 0,
    backgroundColor: (() => {
      if (selectProps.backgroundColor) {
        return selectProps.backgroundColor;
      } else if (isDisabled) {
        return colors.greyscale[200];
      } else if (isFocused || hasValue) {
        return colors.darkBlue;
      }
      return colors.white;
    })(),
    color: isFocused || hasValue ? colors.white : colors.greyscale[500],
    fontFamily: 'Arial Bold',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    transition: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.white,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, { isFocused, hasValue }) => ({
    transition: 'none',
    display: 'block',
    fontSize: '20px',
    fontFamily: 'Helvetica, Arial, Sans-serif',
    marginRight: '12px',
  }),
  placeholder: (provided, { isFocused }) => ({
    color: isFocused ? colors.white : colors.greyscale[500],
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    padding: 0,
    margin: 0,
    top: 38,
    border: `1px solid ${colors.greyscale[600]}`,
    borderTop: 0,
    boxShadow: 'none',
  }),
  container: (provided) => ({
    width: '100%',
    ...provided,
  }),
  option: (provided, { isDisabled, isFocused, data }) => ({
    ...provided,
    margin: 0,
    background: colors.white,
    fontFamily: 'Arial Bold',
    color: (() => {
      if (isFocused) {
        return data.color ?? colors.darkBlue;
      } else if (isDisabled) {
        return colors.greyscale[200];
      } else {
        return colors.greyscale[500];
      }
    })(),
    fontWeight: 'bold',
    cursor: 'pointer',
  }),
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    &#9662;
  </components.DropdownIndicator>
);

const Select = ({
  options,
  placeholder,
  value,
  valueProp,
  onChange,
  isDisabled,
  isOptionDisabled,
  styles,
  ...rest
}) => {
  const handleChange = (option) => {
    return valueProp ? onChange(option[valueProp]) : onChange(option);
  };
  const internalValue = valueProp
    ? options.find((o) => o[valueProp] === value)
    : value;

  return (
    <ReactSelect
      {...rest}
      components={{ DropdownIndicator }}
      value={internalValue}
      onChange={handleChange}
      styles={styles || selectStyles}
      options={options}
      placeholder={placeholder}
      isClearable={false}
      isSearchable={false}
      isDisabled={isDisabled}
      isOptionDisabled={isOptionDisabled}
    />
  );
};

Select.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  valueProp: PropTypes.string,
  styles: PropTypes.object,
};

Select.defaultProps = {
  value: undefined,
  onChange: () => {},
  placeholder: undefined,
  isDisabled: false,
  isOptionDisabled: () => false,
  styles: undefined,
};

export default Select;
