import styled from 'styled-components';
import * as colors from '../styles/colors';
import * as typography from '../styles/typography';

const ProductIndicator = styled.div`
  ${typography.bold}
  border: 1px solid ${colors.greyscale[600]};
  background-color: ${(props) => props.backgroundColor};
  color: white;
  min-height: 36px;
  line-height: 36px;
  padding-left: 8px;
`;

export default ProductIndicator;
