import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { margin, padding, stripUnit } from 'polished';
import { isIE } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import ErrorModal from './ErrorModal';
import LoadingOverlay from './LoadingOverlay';
import { useAppContext } from '../providers/AppProvider';
import { calcViewArea } from '../styles/utils';
import {
  appGutters,
  footerHeight,
  headerHeight,
  backgroundStyles,
  ISI_SCROLL_THRESHOLD,
} from '../styles/constants';

const viewArea = calcViewArea(footerHeight, headerHeight);
const ScrollArea = styled.div`
  overflow-y: scroll;
  max-height: ${calcViewArea(headerHeight)};
`;
const AppGutter = styled.div`
  ${backgroundStyles};
  flex-grow: 1;
  min-width: ${appGutters};
  max-width: 100vw;
`;
const AppWrapper = styled.div`
  min-height: ${viewArea};
  display: flex;
  flex-direction: row;
`;
const AppContentArea = styled.div`
  min-height: ${viewArea};
  ${margin(0, stripUnit(appGutters) * -1)};
  ${padding(0, appGutters)};
  z-index: 2;
  width: 100%;
  max-width: calc(100vw - ${stripUnit(appGutters) * 2}px);
`;
AppContentArea.displayName = 'AppContentArea';

const Layout = ({ children }) => {
  const history = useHistory();
  const {
    globalError,
    isInitializing,
    isLoading,
    setFirstTimeExperience,
    setGlobalError,
  } = useAppContext();
  const scrollAreaRef = useRef(null);
  const footerRef = useRef(null);
  const [showStickyFooter, setShowStickyFooter] = useState(true);
  const [prevEntry, setPrevEntry] = useState({});
  const [scrollReturnPoint, setScrollReturnPoint] = useState(0);
  const [inViewRef, internalFooterVisible, entry] = useInView({
    threshold: [0, ISI_SCROLL_THRESHOLD],
  });
  const showIsi =
    history.location.pathname === '/login' || history.location.pathname === '/'
      ? false
      : true;

  useEffect(() => {
    if (isIE) return;
    const unsubscribe = history.listen(() => {
      // reset FTE whenever someone routes in case they land on
      // a page other than Home first
      setFirstTimeExperience(false);
      // scroll to top of page when the route changes
      if (!scrollAreaRef || !scrollAreaRef.current) return;
      scrollAreaRef.current.scrollTo({ top: 0 });
    });
    return unsubscribe;
  }, []);

  // this effect uses entries from IntersectionObserver to determine which direction
  // a user is scrolling, and uses that to determine how/when to hide or show things
  useEffect(() => {
    if (!entry) return;
    if (showIsi)
      if (prevEntry.intersectionRatio === undefined) {
        setPrevEntry(entry);
      } else {
        const isScrollingDown =
          entry.intersectionRatio > prevEntry.intersectionRatio;
        if (internalFooterVisible) {
          setShowStickyFooter(
            !isScrollingDown && entry.intersectionRatio <= ISI_SCROLL_THRESHOLD,
          );
        } else {
          setShowStickyFooter(true);
        }
        setPrevEntry(entry);
      }
  }, [entry, internalFooterVisible, prevEntry]);

  const setFooterRefs = useCallback(
    (node) => {
      footerRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  const handleToggleFooter = () => {
    if (showStickyFooter) {
      setScrollReturnPoint(scrollAreaRef.current.scrollTop);
      footerRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      if (isIE) {
        scrollAreaRef.current.scrollIntoView();
      } else {
        scrollAreaRef.current.scrollTo({
          behavior: 'smooth',
          top: scrollReturnPoint,
        });
        setScrollReturnPoint(0);
      }
    }
  };

  return (
    <div>
      <AppHeader />
      <ScrollArea ref={scrollAreaRef}>
        <AppWrapper>
          <AppGutter />
          {!isInitializing && (
            <>
              <AppContentArea>{children}</AppContentArea>
              <AppGutter />
            </>
          )}
        </AppWrapper>
        {showIsi && (
          <AppFooter
            isExpanded
            onToggleExpanded={handleToggleFooter}
            ref={setFooterRefs}
          />
        )}
      </ScrollArea>
      {showStickyFooter && showIsi && (
        <AppFooter onToggleExpanded={handleToggleFooter} sticky />
      )}
      {globalError && (
        <ErrorModal
          isOpen
          message={globalError.message}
          onClose={() => setGlobalError(null)}
        />
      )}
      <LoadingOverlay loading={isInitializing || isLoading} />
      {/* <LoadingOverlay loading={isInitializing || isLoading} /> */}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
