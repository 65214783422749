import { size } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

import * as colors from '../styles/colors';
import * as typography from '../styles/typography';

export const labelPositions = {
  LEFT: 'left',
  RIGHT: 'right',
};

const Label = styled.label`
  display: flex;
  align-items: center;
  ${typography.body};
`;

// we can replace the default unicode checkmark with
// one from an icon set by using the char code of the checkmark
// within that set
const CheckboxStyled = styled.input`
  ${size('21px')}
  appearance: none;
  background-color: ${colors.fadedBlue};
  border: none;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.labelPosition === 'left' ? '8px' : '0')};
  margin-right: ${(props) => (props.labelPosition === 'right' ? '8px' : '0')};
  transition: background-color 100ms ease;
  &:after {
    content: '✓';
    display: none;
    color: ${colors.darkBlue};
    font-size: 20px;
  }
  &:checked {
    background-color: ${colors.white};
    &:after {
      display: block;
    }
  }
`;

const Checkbox = ({
  label,
  labelComponent: Label,
  labelPosition,
  ...props
}) => {
  return (
    <Label>
      {labelPosition === 'left' && label}
      <CheckboxStyled
        labelPosition={labelPosition}
        type="checkbox"
        {...props}
      />
      {labelPosition === 'right' && label}
    </Label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(Object.values(labelPositions)),
  labelComponent: PropTypes.elementType,
};

Checkbox.defaultProps = {
  labelPosition: 'right',
  labelComponent: Label,
};

export default Checkbox;
