import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { usePracticeApi } from '../api/practice';
import useApiMethod from '../hooks/useApiMethod';
import usePolicies from '../hooks/usePolicies';
import { useAuthContext } from '../providers/AuthProvider';
import { decoratePolicyWithIndication } from '../helpers';
import usePlans from '../hooks/usePlans';
import { LIBTAYO_ID } from '../constants';

const DEFAULT_STATE = {
  fetchPlans: () => new Promise(),
  firstTimeExperience: true,
  globalError: null,
  isInitializing: false,
  isLoading: false,
  plans: [],
  policies: {
    all: [],
    favorites: [],
    visible: [],
  },
  practice: {
    allPoliciesVisible: true,
    rbm: {
      displayName: '',
      email: '',
    },
  },
  refetchPractice: () => new Promise(),
  setFavoritePolicies: (policies) => {},
  setFirstTimeExperience: (isFte) => {},
  setGlobalError: (err) => {},
  setIsInitializing: (isInitializing) => {},
  setIsLoading: (isLoading) => {},
  setVisiblePolicies: (policies) => {},
};

export const AppContext = React.createContext(DEFAULT_STATE);

export const useAppContext = () => useContext(AppContext);

/**
 * Given a policy ID & indication ID and the full list of
 * policies, find & return the full object for that policy
 * with the given indication
 * @param {Object} policy
 * @param {Number} policy.id
 * @param {Number} policy.indicationId
 * @param {Array} allPolicies
 */
const getFullPolicy = (policy, allPolicies) => {
  const { id, indicationId, productId } = policy;
  const policyObj = allPolicies.find((p) => p.id === id);
  if (!policyObj) return null;
  return decoratePolicyWithIndication(
    policyObj,
    indicationId,
    productId || LIBTAYO_ID,
  );
};

const AppProvider = ({ children }) => {
  const { GetPracticeById } = usePracticeApi();
  const { policies: allPolicies } = usePolicies();

  const { fetchPlans, plans: allPlans } = usePlans();
  const { loading: isLoadingAuth, user } = useAuthContext();
  const {
    apiMethod: fetchPractice,
    data: practice,
    loading: isLoadingPractice,
    reset: resetPractice,
  } = useApiMethod(GetPracticeById);

  const [globalError, setGlobalError] = useState(null);
  const [firstTimeExperience, setFirstTimeExperience] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [visiblePolicies, setVisiblePolicies] = useState([]);
  const [favoritePolicies, setFavoritePolicies] = useState([]);

  const getIsInitializing = () => {
    return [isLoadingAuth, isLoadingPractice && !practice].some(Boolean);
  };

  const fetchPlansOnce = async () => {
    // don't refetch plans if we already have them,
    // since they do not change very often
    if (allPlans.length) return;
    setIsLoading(true);
    await fetchPlans();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user?.practiceId) {
      resetPractice();
    } else {
      fetchPractice(user.practiceId);
    }
  }, [user?.practiceId]);
  const refetchPractice = () => {
    fetchPractice(user.practiceId);
  };

  useEffect(() => {
    const allDataLoaded = [
      allPolicies.length,
      user?.policiesShownOnHomepage,
      practice?.policies,
    ].every(Boolean);

    if (!allDataLoaded) {
      setFavoritePolicies([]);
      setVisiblePolicies([]);
      setFirstTimeExperience(true);
      return;
    }
    // get & set visible policies
    const fullVisiblePolicies = practice.policies
      .map((policy) => getFullPolicy(policy, allPolicies))
      .filter(Boolean);
    setVisiblePolicies(fullVisiblePolicies);

    // get & set favorite policies
    const fullFavePolicies = user.policiesShownOnHomepage
      .filter(
        (p) =>
          practice.allPoliciesVisible ||
          practice.policies.find((q) => q.id === p.id),
      )
      .map((policy) => getFullPolicy(policy, allPolicies))
      .filter(Boolean);
    setFavoritePolicies(fullFavePolicies);
    if (firstTimeExperience) {
      setFirstTimeExperience(!fullFavePolicies.length);
    }
  }, [allPolicies, user?.policiesShownOnHomepage, practice?.policies]);

  return (
    <AppContext.Provider
      value={{
        ...DEFAULT_STATE,
        fetchPlans: fetchPlansOnce,
        firstTimeExperience,
        globalError,
        isInitializing: getIsInitializing(),
        isLoading,
        plans: allPlans,
        policies: {
          all: allPolicies,
          favorites: favoritePolicies,
          visible: visiblePolicies,
        },
        practice,
        refetchPractice,
        setGlobalError,
        setFavoritePolicies,
        setFirstTimeExperience,
        setIsLoading,
        setVisiblePolicies,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default AppProvider;
