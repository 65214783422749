import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import PageFooter from '../../components/PageFooter';
import * as typography from '../../styles/typography';
import { siteMetadata } from '../../constants/siteMetadata';
import { stripedBackgroundStyles, ProductText } from '../../styles/constants';

const LoginWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  overflow: auto;
  padding: 24px 16px;
  box-sizing: border-box;
  ${stripedBackgroundStyles}
`;

const WelcomeBox = styled.h2`
  color: ${({ theme }) => theme.foreground};
  ${typography.bold};
  ${typography.h2};
`;

const Login = () => {
  const { path } = useRouteMatch();

  return (
    <LoginWrapper>
      <Helmet title={siteMetadata[path].title}></Helmet>
      <WelcomeBox>
        Welcome to the Payer Policy Portal for{' '}
        <ProductText>LIBTAYO</ProductText>!
      </WelcomeBox>
      <Route exact path="/login" component={LoginForm} />
      <Route exact path="/login/forgot" component={ForgotPasswordForm} />
      <PageFooter />
    </LoginWrapper>
  );
};

export default Login;
