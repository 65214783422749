// Sort falsy values to the bottom
const nullCompare = (a, b) => {
  if (!a && b) return 1;
  if (a && !b) return -1;
  return 0;
};

const sortRecords = (records, sortBy, order) => {
  if (!order || !sortBy) return records;

  // Spread to create a copy:
  const sortedRecords = [...records].sort((a, b) => {
    if (!a[sortBy] || !b[sortBy]) return nullCompare(a[sortBy], b[sortBy]);
    return a[sortBy].localeCompare(b[sortBy]);
  });

  return order === 'asc' ? sortedRecords : sortedRecords.reverse();
};

export default sortRecords;
