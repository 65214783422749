import React, { useEffect } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PageWrapper from '../components/PageWrapper';
import PageHeading from '../components/PageHeading';
import PathwayStatusList from '../components/PathwayStatusList';
import { ListTextButton } from '../components/List';
import { QueryParams, QueryParam } from '../components/QueryParam';
import routes from '../constants/routes';

import usePathwayStatuses from '../hooks/usePathwayStatuses';

import {
  getPathwayStatusIndicationLabelById,
  getProductById,
  getUsStateFullNameFromAbbreviation,
} from '../helpers';

const ContentWrapper = styled.div`
  width: 100%;
`;

const PathwayStatusPageHeading = styled(PageHeading)`
  margin-bottom: 0;
`;

const SourceText = styled.div`
  margin-top: 1em;
  width: 100%;
  font-size: 12px;
  margin-left: -16px;
`;

const TableControlsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1em;
`;

const NO_RESULTS_TEXT = 'Your current search has no corresponding results.';
const sourceText = (dateString) =>
  `The Dedham Group Research (as of ${dateString})`;

const PathwayStatusResults = () => {
  const { search } = useLocation();
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const productId = parseInt(params.productId);
  const indicationId = params.indicationId;
  const usState = params.usState;
  const product = getProductById(productId);

  const {
    fetchPathwayStatuses,
    pathwayStatuses,
    loading,
    fetchPathwayStatusSourceDate,
    pathwayStatusSourceDate,
  } = usePathwayStatuses();

  useEffect(() => {
    fetchPathwayStatuses();
    fetchPathwayStatusSourceDate();
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        <PathwayStatusPageHeading>
          Pathway Status: Results
        </PathwayStatusPageHeading>
      </ContentWrapper>

      <QueryParams>
        <QueryParam label="Product" value={product.label} />
        <QueryParam
          label="Indication"
          value={getPathwayStatusIndicationLabelById(indicationId)}
        />
        <QueryParam
          label="State/Territory"
          value={getUsStateFullNameFromAbbreviation(usState)}
        />
      </QueryParams>

      <TableControlsRow>
        <ListTextButton to={routes.pathwayStatus}>New Query</ListTextButton>
      </TableControlsRow>

      <PathwayStatusList
        loading={loading}
        pathwayStatuses={pathwayStatuses}
        productId={productId}
        usState={usState}
        indicationId={indicationId}
        noResultsText={NO_RESULTS_TEXT}
      />

      <SourceText>
        <strong>Source:</strong>{' '}
        {sourceText(
          `${pathwayStatusSourceDate.month} ${pathwayStatusSourceDate.year}`,
        )}
        &#46;
      </SourceText>
    </PageWrapper>
  );
};

export default PathwayStatusResults;
