import * as colors from './colors';

const commonTheme = {
  disabled: colors.greyscale[600],
  error: colors.pink,
  highlight: colors.yellowGreen,
};

export const lightTheme = {
  ...commonTheme,
  accent: colors.lightBlue,
  background: colors.white,
  foreground: colors.darkBlue,
  input: colors.white,
  inputBorder: `1px solid ${colors.greyscale[600]}`,
  placeholder: colors.greyscale[600],
  tableRow: colors.greyscale[100],
  text: colors.greyscale[600],
};

export const darkTheme = {
  ...commonTheme,
  accent: colors.yellowGreen,
  background: colors.darkBlue,
  foreground: colors.blankWhite,
  input: colors.fadedBlue,
  inputBorder: 'none',
  placeholder: colors.blueGrey,
  tableRow: colors.darkBlue,
  text: colors.white,
};
