import React from 'react';
import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as typography from '../../styles/typography';
import Link from '../Link';
import { PRESCRIBING_INFORMATION } from '../../constants';

const PiText = styled.p`
  ${typography.bold};
  color: ${colors.darkBlue};
`;
const PiLink = styled(Link)``;

const PrescribingInformation = () => {
  return (
    <PiLink
      target="_blank"
      rel="noreferrer"
      href={PRESCRIBING_INFORMATION.LIBTAYO.value}
    >
      <PiText>Prescribing Information</PiText>
    </PiLink>
  );
};

export default PrescribingInformation;
