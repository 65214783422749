import React from 'react';
import styled from 'styled-components';

import Link from '../components/Link';
import PageWrapper from '../components/PageWrapper';
import PageHeading from '../components/PageHeading';

import { bold } from '../styles/typography';
import * as colors from '../styles/colors';

const TextHeading = styled.h4`
  ${bold}
  margin-bottom: 0;
`;

const BoldText = styled.span`
  ${bold}
`;

const ColoredBoldText = styled(BoldText)`
  color: ${colors.darkBlue};
`;

const SubScript = styled.span`
  font-size: 11px;
`;

const EndSubScript = styled.span`
  font-size: 11px;
  padding-right: 4px;
`;

const Text = styled.p`
  margin: 0 0 1rem 0;
`;
// distinct from page wrapper, which adds the banners,
// this component wraps the text content of the page
const ContentWrapper = styled.div`
  width: 100%;
`;
const LinkBold = styled(Link)`
  ${bold}
  text-decoration: underline;
`;

const Resources = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeading>Resources</PageHeading>

        <Text>
          From ensuring your eligible patients have access to LIBTAYO, to
          helping them navigate reimbursement, LIBTAYO Surround<sup>&reg;</sup>{' '}
          is here to help.
        </Text>

        <TextHeading>What is LIBTAYO Surround?</TextHeading>
        <Text>
          LIBTAYO Surround is a support program that offers patient support,
          reimbursement assistance, and access solutions.
        </Text>

        <Text>
          Our dedicated team and wide range of resources can help patients
          access LIBTAYO and answer any questions you might have.
        </Text>

        <TextHeading>For more information:</TextHeading>
        <ul>
          <li>
            Call{' '}
            <Link href="tel:1-877-542-8296">
              <BoldText>1.877.LIBTAYO</BoldText>
            </Link>
            &nbsp;(1.877.542.8296), <ColoredBoldText>Option 1</ColoredBoldText>,
            Monday&ndash;Friday, 8&nbsp;<SubScript>AM</SubScript>–8&nbsp;
            <EndSubScript>PM</EndSubScript>
            Eastern time
          </li>
          <li>
            Visit{' '}
            <Link
              target="_blank"
              href="https://libtayohcp.com/libtayo-surround-resources#support-tools"
            >
              https://libtayohcp.com/libtayo-surround-resources#support-tools
            </Link>
          </li>
          <li>
            Fax to <Link href="tel:1-833-853-8362">1.833.853.8362</Link>
          </li>
        </ul>
        <Text>
          <LinkBold
            target="_blank"
            href="https://www.libtayohcp.com/libtayo-surround"
          >
            Click here
          </LinkBold>{' '}
          to learn more about reimbursement support and download helpful
          resources.
        </Text>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Resources;
