import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import UserListRow from './UserListRow';
import UserForm from './UserForm';
import { columnWidths } from './UserList.constants';
import { usePracticeApi } from '../../api/practice';
import { useUserApi } from '../../api/user';
import useRowForm, { NEW_ROW_ID } from '../../hooks/useRowForm';
import { useAppContext } from '../../providers/AppProvider';
import { useAuthContext } from '../../providers/AuthProvider';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import FullWidthWrapper from '../FullWidthWrapper';
import LoadingSpinner from '../LoadingSpinner';
import * as roles from '../../constants/roles';
import * as colors from '../../styles/colors';

import { ListTitleBar, List, DataColumn, TitleColumn } from '../List';

const UserList = ({ onCloseNewRow, showNewRow }) => {
  const { CreateUser, DeleteUser, EditUser } = useUserApi();
  const { ListenToPracticeUserUpdates } = usePracticeApi();
  const { setGlobalError } = useAppContext();
  const { user } = useAuthContext();

  const createUser = async (newUser) => {
    try {
      return await CreateUser(roles.GENERAL_USER, newUser, user.practiceId);
    } catch (err) {
      console.log(err);
      setGlobalError({
        error: err,
        message: err.message,
      });
    }
  };
  const {
    activateEditMode,
    deactivateEditMode,
    clearDeleteConfirmation,
    data: users,
    deleteConfirmation,
    editModeRows,
    handleCreate,
    handleDelete,
    handleUpdate,
    isLoading,
    pendingChanges,
    showDeleteConfirmation,
  } = useRowForm({
    apiMethods: useMemo(
      () => ({
        create: createUser,
        subscribe: (onSuccess) => {
          return ListenToPracticeUserUpdates(user.practiceId, onSuccess);
        },
        update: EditUser,
        delete: (newUser) => DeleteUser(newUser, user.practiceId),
      }),
      [user],
    ),
    onCreateSuccess: () => {
      onCloseNewRow();
    },
  });

  const generalUsers = users.filter((u) => u.role === roles.GENERAL_USER);

  return (
    <FullWidthWrapper>
      <ListTitleBar backgroundColor={colors.yellowGreen}>
        <TitleColumn width={columnWidths.displayName}>Office Users</TitleColumn>
        <DataColumn width={columnWidths.email}>Email</DataColumn>
        <DataColumn width={columnWidths.editButtons} />
        <DataColumn width={columnWidths.passwordReset} />
      </ListTitleBar>
      {isLoading && <LoadingSpinner size={100} />}
      {!isLoading && (
        <List>
          {showNewRow && !pendingChanges.includes(NEW_ROW_ID) && (
            <UserForm
              disabled={showNewRow}
              isSaving={pendingChanges.includes(NEW_ROW_ID)}
              onCancel={onCloseNewRow}
              onSave={handleCreate}
            />
          )}
          {!!generalUsers.length &&
            generalUsers.map((u, i) =>
              editModeRows.includes(u.id) ? (
                <UserForm
                  initialValue={u}
                  isSaving={pendingChanges.includes(u.id)}
                  key={i}
                  onCancel={() => deactivateEditMode(u)}
                  onSave={handleUpdate}
                />
              ) : (
                <UserListRow
                  key={i}
                  onEditUser={() => activateEditMode(u)}
                  onRemoveUser={() => showDeleteConfirmation(u)}
                  user={u}
                />
              ),
            )}
        </List>
      )}
      <ConfirmDeleteModal
        isOpen={deleteConfirmation.isOpen}
        deleteTargetName="User"
        onContinue={() => handleDelete()}
        onCancel={clearDeleteConfirmation}
      />
    </FullWidthWrapper>
  );
};

UserList.propTypes = {
  noResultsText: PropTypes.string,
  onCloseNewRow: PropTypes.func,
  showNewRow: PropTypes.bool,
};

export default UserList;
