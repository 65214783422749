import { useFirebaseContext } from '../providers/FirebaseProvider';

const CURRENT_COLLECTIONS_COLLECTION_NAME = 'currentCollections';
const CURRENT_COLLECTIONS_PLANS_KEY = 'plans';
const CURRENT_COLLECTIONS_POLICIES_KEY = 'policies';

/**
 * Hook to manage the retrieval of the current Firestore collection names for
 * policies and plans.
 *
 * @see https://developmentnow.atlassian.net/browse/NAVFEATURE-95
 */
const useCurrentCollections = () => {
  const firebase = useFirebaseContext();

  const getCurrentCollectionName = async (type) => {
    const ref = await firebase.firestore
      .collection(CURRENT_COLLECTIONS_COLLECTION_NAME)
      .doc(type)
      .get();
    return ref?.data()?.currentCollectionName;
  };

  const getCurrentPlansCollectionName = async () =>
    await getCurrentCollectionName(CURRENT_COLLECTIONS_PLANS_KEY);
  const getCurrentPoliciesCollectionName = async () =>
    await getCurrentCollectionName(CURRENT_COLLECTIONS_POLICIES_KEY);

  return {
    getCurrentPlansCollectionName,
    getCurrentPoliciesCollectionName,
  };
};

export default useCurrentCollections;
