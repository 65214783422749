import styled, { css } from 'styled-components';

export const light = css`
  font-family: 'Arial';
`;
export const regular = css`
  font-family: 'Arial Regular';
`;
export const bold = css`
  font-family: 'Arial Bold';
`;

// 1rem = 16pt; see index.css

// 28pt default
// avenir bold
export const h1 = css`
  font-size: 1.75rem;
`;

// 24pt default
// avenir bold
export const h2 = css`
  font-size: 1.5rem;
`;

// 22pt default
// avenir medium
export const h3 = css`
  font-size: 1.375rem;
`;

export const h4 = css`
  font-size: 1.25rem;
`;
export const h5 = css`
  font-size: 1.125rem;
`;
export const h6 = css`
  font-size: 1rem;
`;

// 12pt default
// avenir regular
export const body = css`
  font-size: 0.75rem;
`;

export const caption = css`
  font-size: 0.625rem;
`;
export const legal = css`
  font-size: 0.55rem;
`;

export const IsiHeaderLarge = styled.span`
  font-size: 32px;
`;
export const IsiHeaderMedium = styled.span`
  font-size: 28px;
`;
export const IsiHeaderSmall = styled.span`
  font-size: 24px;
`;
