import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FIELDS } from './PolicyList.constants';
import { policyPropType } from '../../propTypes';

import FullWidthWrapper from '../FullWidthWrapper';
import LoadingSpinner from '../LoadingSpinner';
import FilterableListTitleBar from '../FilterableListTitleBar';
import SortableListTitleColumn from '../SortableListTitleColumn';
import * as colors from '../../styles/colors';
import { useAuthContext } from '../../providers/AuthProvider';

import {
  List,
  ListBodyRow,
  ListHeaderRow,
  IconColumn,
  LIST_PADDING_X,
} from '../List';
import PolicyListRow from './PolicyListRow';
import { filterPolicies, filterPoliciesByProductId } from './filterPolicies';
import sortPolicies from './sortPolicies';
import * as typography from '../../styles/typography';
import useTableFilters from '../../hooks/useTableFilters';
import useTableSort from '../../hooks/useTableSort';
import { MEDICATION_OPTIONS } from '../../constants';

const ProductTitle = styled.div`
  margin: 35px calc(${LIST_PADDING_X} * 2) 0;
  color: ${(props) => props.color};
  ${typography.h4};
  ${typography.bold};
`;

const PolicyList = ({
  loading,
  noResultsText,
  policies,
  showMeta,
  showVisibleToPractice,
  title,
  product,
  titleBarBackgroundColor,
}) => {
  const { getSortForField, order, setSort, sortBy } = useTableSort(policies);
  const { clearAllFilters, filters, setFilterForField } = useTableFilters();
  const { user } = useAuthContext();
  const filterFields = product?.fields ?? FIELDS;

  const filteredPolicies = useMemo(() => {
    const filteredPolicies = filterPolicies(policies, filters);
    return sortPolicies(filteredPolicies, sortBy, order);
  }, [policies, filters, sortBy, order]);
  const isTableFiltered = useMemo(() => {
    return Object.values(filters).some(Boolean);
  }, [filters]);

  useEffect(() => {}, [filters]);

  const renderHeaderCell = ({ columnWidth, label, value }) => (
    <SortableListTitleColumn
      key={value}
      onClick={() => setSort(value)}
      order={getSortForField(value)}
      text={label}
      width={columnWidth}
    />
  );

  const renderTableForProduct = (product, filteredPolicies) => (
    <List>
      <ListHeaderRow>
        {showVisibleToPractice && <IconColumn>Viewable to Practice</IconColumn>}
        {user.role !== 'rbm' && <IconColumn>Show on Homepage</IconColumn>}
        {product.fields.map(renderHeaderCell)}
      </ListHeaderRow>
      {!!filteredPolicies.length &&
        filteredPolicies.map((policy, index) => (
          <PolicyListRow
            product={product}
            policy={policy}
            showMeta={showMeta}
            showVisibleToPractice={showVisibleToPractice}
            key={index}
          />
        ))}
      {!filteredPolicies.length && noResultsText && (
        <ListBodyRow>
          <div>{noResultsText}</div>
        </ListBodyRow>
      )}
    </List>
  );

  const renderAllTables = () => {
    return MEDICATION_OPTIONS.map((option) => (
      <React.Fragment key={option.value}>
        <ProductTitle color={option.color}>{option.expandedLabel}</ProductTitle>
        {renderTableForProduct(
          option,
          filterPoliciesByProductId(filteredPolicies, option.value),
        )}
      </React.Fragment>
    ));
  };

  return (
    <FullWidthWrapper>
      <FilterableListTitleBar
        backgroundColor={titleBarBackgroundColor}
        indicatorColor={product?.color}
        title={title}
        isListFiltered={isTableFiltered}
        onClearFilters={clearAllFilters}
        filterFields={filterFields}
        filters={filters}
        onSetFilter={setFilterForField}
      />

      {loading && <LoadingSpinner size={100} />}
      {!loading && product
        ? renderTableForProduct(product, filteredPolicies)
        : renderAllTables()}
    </FullWidthWrapper>
  );
};

PolicyList.propTypes = {
  titleBarBackgroundColor: PropTypes.string,
  loading: PropTypes.bool,
  noResultsText: PropTypes.string,
  policies: PropTypes.arrayOf(policyPropType).isRequired,
  showMeta: PropTypes.bool,
  showVisibleToPractice: PropTypes.bool,
  title: PropTypes.string,
  product: PropTypes.object,
};

PolicyList.defaultProps = {
  loading: false,
  showMeta: false,
  showVisibleToPractice: true,
  titleBarBackgroundColor: colors.darkBlue,
};

export default PolicyList;
