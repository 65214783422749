import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { userColumnWidths } from './PracticeList.constants';
import TextButton from '../TextButton';
import { ListRow, DataColumn } from '../List';
import { useAuthContext } from '../../providers/AuthProvider';
import * as typography from '../../styles/typography';

// SUB-LIST
const SubListRow = styled(ListRow)`
  padding: 10px 20px;
`;
const BoldColumn = styled(DataColumn)`
  ${typography.bold};
  color: ${({ theme }) => theme.foreground};
`;

const SendEmailButton = styled(TextButton)`
  text-transform: none;
`;

const SuperUserListRow = ({ onEditUser, onRemoveUser, user }) => {
  const { sendPasswordResetEmail } = useAuthContext();

  return (
    <SubListRow>
      <BoldColumn width={userColumnWidths.displayName}>
        {user.displayName}
      </BoldColumn>
      <DataColumn width={userColumnWidths.email}>{user.email}</DataColumn>
      <DataColumn width={userColumnWidths.editButtons} justify="flex-end">
        <TextButton onClick={onEditUser}>EDIT</TextButton>
        &nbsp;|&nbsp;
        <TextButton onClick={onRemoveUser}>REMOVE</TextButton>
      </DataColumn>
      <DataColumn width={userColumnWidths.passwordReset} justify="flex-end">
        <SendEmailButton onClick={() => sendPasswordResetEmail(user.email)}>
          Send Email to Set Password
        </SendEmailButton>
      </DataColumn>
    </SubListRow>
  );
};

SuperUserListRow.propTypes = {
  onEditUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  user: PropTypes.object.isRequired,
};

export default SuperUserListRow;
