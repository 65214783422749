import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import styled from 'styled-components';
import PageWrapper from '../components/PageWrapper';
import PageHeading from '../components/PageHeading';
import PathwaySelect from '../components/PathwaySelect';
import JumboButton from '../components/JumboButton';
import { darkTheme } from '../styles/themes';
import { LIBTAYO_ID } from '../constants';
import { getProductById } from '../helpers';

const ContentWrapper = styled.div`
  width: 100%;
`;

const SubmitButton = styled(JumboButton).attrs({
  theme: darkTheme,
})`
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 1rem 6rem;
`;

const PathwayStatusSelection = () => {
  const history = useHistory();

  const product = getProductById(LIBTAYO_ID);
  const [usState, setUsState] = useState(null);
  const [indication, setIndication] = useState(null);

  const isSelectionComplete = product && usState && indication;

  const handleSubmit = () => {
    const params = {
      productId: product.value,
      usState,
      indicationId: indication.value,
    };

    // @see https://github.com/gabidavila/react-usa-map/issues/43
    if (params.usState === 'DC2') {
      params.usState = 'DC';
    }
    history.push(`/pathway-status/results?${qs.stringify(params)}`);
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeading>Pathway Status</PageHeading>
      </ContentWrapper>

      <PathwaySelect
        product={product}
        usState={usState}
        indication={indication}
        setUsState={setUsState}
        setIndication={setIndication}
      />

      <SubmitButton onClick={handleSubmit} disabled={!isSelectionComplete}>
        Continue
      </SubmitButton>
    </PageWrapper>
  );
};

export default PathwayStatusSelection;
