import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { device } from '../styles/breakpoints';
import { important, margin, stripUnit } from 'polished';

import { pageHorizontalPadding } from '../styles/constants';

// important margin helps enforce consistent positioning for banner ads
// regardless of other page content.  Would love a good way to remove this
// without carving out exceptions in other stylesheets.
const BannerWrapper = styled.a`
  ${important(margin('20px', 0))};
  align-items: center;
  background-color: #f5f8fa;
  border-bottom: 1px solid #d1d1d1;
  border-top: 1px solid #d1d1d1;
  cursor: pointer;
  display: flex;
  height: 90px;
  justify-content: center;
  width: calc(100% + ${stripUnit(pageHorizontalPadding) * 2}px);
  position: relative;

  @media ${device.laptop} {
    height: 75px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: end;
  position: absolute;
  right: 20px;
  height: 100%;
  font-size: 0.75rem;

  @media ${device.laptop} {
    height: 60px;
  }
`;

const BannerAd = ({ imgSrc, href, alt, jobNumber }) => (
  <BannerWrapper href={href} target="_blank" rel="noreferrer">
    <img src={imgSrc} alt={alt} />
    <TextWrapper>{jobNumber}</TextWrapper>
  </BannerWrapper>
);

BannerAd.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  jobNumber: PropTypes.string.isRequired,
};

export default BannerAd;
