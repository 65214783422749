import styled, { css } from 'styled-components';

import * as colors from './colors';

import stripedBackgroundImage from '../assets/images/striped_background@2x.png';

export const appGutters = '120px';
export const boxShadowStandard = '0px 3px 5px 0px rgba(0, 0, 0, 0.25)';
export const headerHeight = '120px';
export const footerHeight = '185px';
export const pageHorizontalPadding = '20px';
export const ISI_SCROLL_THRESHOLD = 0.04;

export const backgroundStyles = css`
  background-color: ${colors.blankWhite};
`;

export const stripedBackgroundStyles = css`
  background: url(${stripedBackgroundImage}) no-repeat;
  background-position: top -100px right 20px;
  background-size: 200px;
`;

export const ProductText = styled.span`
  color: ${colors.pink};
`;
