import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import PathwayStatusSelection from './PathwayStatusSelection';
import PathwayStatusResults from './PathwayStatusResults';

const PathwayStatus = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/results`} component={PathwayStatusResults} />
      <Route path={path} component={PathwayStatusSelection} />
    </Switch>
  );
};

export default PathwayStatus;
