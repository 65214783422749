import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Markdown from 'react-markdown';
import { padding } from 'polished';

import { ChevronUp } from './Icons';
import { linkStyles } from './Link';
import PageFooter from './PageFooter';
import PageFooterDisclaimerText from './PageFooterDisclaimerText';
import * as FOOTER_CONTENT from '../constants/footerContent';
import { PRESCRIBING_INFORMATION } from '../constants';
import { darkTheme } from '../styles/themes';
import * as typography from '../styles/typography';
import { appGutters, footerHeight } from '../styles/constants';

const PRESCRIBING_INFO_LINK_LIBTAYO = PRESCRIBING_INFORMATION.LIBTAYO.value;

const AppFooterWrapper = styled.div`
  ${(props) => padding(0, appGutters, props.sticky ? 0 : '16px')};
  background-color: ${({ theme }) => theme.background};
  border-top: 6px solid ${({ theme }) => theme.highlight};
  bottom: 0;
  color: ${({ theme }) => theme.text};
  cursor: ${(props) => (props.isExpanded ? 'auto' : 'pointer')};
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.sticky ? footerHeight : 'fit-content')};
  min-height: ${footerHeight};
  max-height: ${(props) => (props.sticky ? footerHeight : 'fit-content')};
  overflow: ${(props) => (props.sticky ? 'hidden' : 'visible')};
  position: ${(props) => (props.sticky ? 'fixed' : 'relative')};
  scrollbar-gutter: stable;
  z-index: ${(props) => (props.sticky ? 10 : 5)};
  & ul {
    padding-inline-start: 20px;
    & li {
      padding-bottom: 2px;
    }
  }
  & p,
  ul {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
`;
const BigChevron = styled.div`
  align-items: center;
  cursor: pointer;
  fill: colors.yellowGreen;
  display: flex;
  padding: 16px 0 0;
  width: fit-content;
  & svg {
    transform: rotate(${(props) => (props.isExpanded ? '180deg' : '0deg')});
    & * {
      fill: ${({ theme }) => theme.accent};
      stroke: ${({ theme }) => theme.accent};
    }
  }
`;

const FooterColumns = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;
`;

const FooterColumn = styled.div`
  font-size: 0.9rem;
`;

const StyledMarkdown = styled(Markdown)`
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 24px;
  }
  h2,
  h3,
  h4 {
    margin: 0 0 0.5rem 0;
  }
  p,
  li {
    font-size: 1rem;
    margin-block: 0.5rem;
  }
  p + h2,
  p + h3,
  p + h4,
  ul + h2,
  ul + h3,
  ul + h4 {
    margin-top: 1rem;
  }
  strong {
    ${typography.bold}
  }
`;

const PrescribingInfo = styled.div`
  margin-top: 15px;
  margin-bottom: 8px;
  ${typography.bold};
  color: ${({ theme }) => theme.foreground};
  & > a {
    ${linkStyles};
    text-decoration: underline;
  }
`;

const AppFooter = forwardRef(
  ({ className, isExpanded, onToggleExpanded, sticky }, ref) => (
    <ThemeProvider theme={darkTheme}>
      <AppFooterWrapper
        className={className}
        isExpanded={isExpanded}
        onClick={() => !isExpanded && onToggleExpanded()}
        ref={ref}
        sticky={sticky}
      >
        <BigChevron isExpanded={isExpanded} onClick={onToggleExpanded}>
          {ChevronUp}
        </BigChevron>
        <FooterColumns>
          <FooterColumn>
            <StyledMarkdown>
              {FOOTER_CONTENT.ISI_COPY_MAIN_MARKDOWN}
            </StyledMarkdown>

            <PrescribingInfo>
              Please see full&nbsp;
              <a
                href={PRESCRIBING_INFO_LINK_LIBTAYO}
                target="_blank"
                rel="noreferrer"
              >
                Prescribing Information
              </a>
              .
            </PrescribingInfo>
            <PageFooterDisclaimerText isISI={true} />
          </FooterColumn>
          <FooterColumn>
            <StyledMarkdown>
              {FOOTER_CONTENT.ISI_COPY_SIDEBAR_MARKDOWN}
            </StyledMarkdown>
          </FooterColumn>
        </FooterColumns>
        <PageFooter isISI={true} />
      </AppFooterWrapper>
    </ThemeProvider>
  ),
);

// we need this because we're using React.forwardRef()
AppFooter.displayName = 'AppFooter';
AppFooter.propTypes = {
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  onToggleExpanded: PropTypes.func.isRequired,
  sticky: PropTypes.bool,
};

export default AppFooter;
