import PropTypes from 'prop-types';

export const ChevronUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.951"
    height="9.986"
    viewBox="0 0 16.951 9.986"
  >
    <g id="back-button" transform="translate(15.951 1) rotate(90)">
      <path
        id="Path_69"
        data-name="Path 69"
        d="M1.024,7.475,7.776.723a.423.423,0,1,0-.6-.6L.125,7.177a.422.422,0,0,0,0,.6l7.053,7.05a.426.426,0,0,0,.3.125.413.413,0,0,0,.3-.125.422.422,0,0,0,0-.6Z"
        transform="translate(0 0)"
        fill="#000000"
        stroke="#000000"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export const Filters = (
  <svg
    id="filter"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      id="Path_325"
      data-name="Path 325"
      d="M14.347,0H3.126A3.129,3.129,0,0,0,0,3.126V14.347a3.129,3.129,0,0,0,3.126,3.126H14.347a3.129,3.129,0,0,0,3.126-3.126V3.126A3.129,3.129,0,0,0,14.347,0Zm2.1,14.347a2.1,2.1,0,0,1-2.1,2.1H3.126a2.1,2.1,0,0,1-2.1-2.1V3.126a2.1,2.1,0,0,1,2.1-2.1H14.347a2.1,2.1,0,0,1,2.1,2.1Z"
      transform="translate(0 0)"
      fill="#fff"
    />
    <path
      id="Path_326"
      data-name="Path 326"
      d="M76.841,81.431h-7.8a1.608,1.608,0,0,0-3.049,0H64.779a.512.512,0,0,0,0,1.024H66a1.608,1.608,0,0,0,3.049,0h7.8a.512.512,0,1,0,0-1.024Zm-9.321,1.1a.585.585,0,1,1,.585-.585A.585.585,0,0,1,67.52,82.527Z"
      transform="translate(-62.074 -77.592)"
      fill="#fff"
    />
    <path
      id="Path_327"
      data-name="Path 327"
      d="M76.841,209.964H75.624a1.608,1.608,0,0,0-3.049,0h-7.8a.512.512,0,1,0,0,1.024h7.8a1.608,1.608,0,0,0,3.049,0h1.217a.512.512,0,1,0,0-1.024Zm-2.741,1.1a.585.585,0,1,1,.585-.585A.585.585,0,0,1,74.1,211.06Z"
      transform="translate(-62.074 -201.739)"
      fill="#fff"
    />
    <path
      id="Path_328"
      data-name="Path 328"
      d="M76.841,338.5h-5.6a1.608,1.608,0,0,0-3.049,0h-3.41a.512.512,0,0,0,0,1.024h3.41a1.608,1.608,0,0,0,3.049,0h5.6a.512.512,0,1,0,0-1.024Zm-7.128,1.1a.585.585,0,1,1,.585-.585A.585.585,0,0,1,69.714,339.593Z"
      transform="translate(-62.074 -325.886)"
      fill="#fff"
    />
  </svg>
);
export const MagnifierLight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.877"
    height="29.884"
    viewBox="0 0 29.877 29.884"
  >
    <path
      id="Path_75"
      data-name="Path 75"
      d="M29.667,28.36,22.405,21.1a12.752,12.752,0,1,0-1.259,1.259l7.262,7.262a.9.9,0,0,0,.629.265.873.873,0,0,0,.629-.265A.9.9,0,0,0,29.667,28.36ZM1.831,12.742A10.953,10.953,0,1,1,12.784,23.7,10.965,10.965,0,0,1,1.831,12.742Z"
      transform="translate(-0.049)"
      fill="#a0a0a0"
    />
  </svg>
);
export const StarFilled = ({ color = '#ffd100' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.965"
    height="25.756"
    viewBox="0 0 26.965 25.756"
  >
    <g id="Star" transform="translate(0.511 0.5)">
      <path
        id="Star-2"
        data-name="Star"
        d="M25.33,20.814l-8.4-.779-3.338-7.751a.673.673,0,0,0-1.237,0L9.015,20.035l-8.4.779A.673.673,0,0,0,.23,21.99l6.34,5.57L4.714,35.793a.673.673,0,0,0,1,.727l7.256-4.309,7.256,4.309a.673.673,0,0,0,1-.727L19.373,27.56l6.34-5.57A.673.673,0,0,0,25.33,20.814Z"
        transform="translate(0 -11.877)"
        fill={color}
        stroke={color}
        strokeWidth="1"
      />
    </g>
  </svg>
);
StarFilled.propTypes = {
  color: PropTypes.string,
};
export const StarOutlined = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.965"
    height="25.756"
    viewBox="0 0 26.965 25.756"
  >
    <g id="Star" transform="translate(0.511 0.5)">
      <path
        id="Star-2"
        data-name="Star"
        d="M25.33,20.814l-8.4-.779-3.338-7.751a.673.673,0,0,0-1.237,0L9.015,20.035l-8.4.779A.673.673,0,0,0,.23,21.99l6.34,5.57L4.714,35.793a.673.673,0,0,0,1,.727l7.256-4.309,7.256,4.309a.673.673,0,0,0,1-.727L19.373,27.56l6.34-5.57A.673.673,0,0,0,25.33,20.814Z"
        transform="translate(0 -11.877)"
        fill="#fff"
        stroke="#ffd100"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export const ToggleOff = (
  <svg
    id="Radio"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="20"
    viewBox="0 0 32 20"
  >
    <g
      id="Rectangle_189"
      data-name="Rectangle 189"
      fill="#fff"
      stroke="#003454"
      strokeWidth="2"
      opacity="0.198"
    >
      <rect width="32" height="20" rx="4" stroke="none" />
      <rect x="1" y="1" width="30" height="18" rx="3" fill="none" />
    </g>
    <rect
      id="Rectangle_190"
      data-name="Rectangle 190"
      width="8"
      height="12"
      rx="2"
      transform="translate(5 4)"
      fill="#003454"
      opacity="0.204"
    />
  </svg>
);
export const ToggleOn = (
  <svg
    id="Radio"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="20"
    viewBox="0 0 32 20"
  >
    <g
      id="Rectangle_191"
      data-name="Rectangle 191"
      fill="#fff"
      stroke="#003454"
      strokeWidth="2"
    >
      <rect width="32" height="20" rx="4" stroke="none" />
      <rect x="1" y="1" width="30" height="18" rx="3" fill="none" />
    </g>
    <rect
      id="Rectangle_192"
      data-name="Rectangle 192"
      width="8"
      height="12"
      rx="2"
      transform="translate(20 4)"
      fill="#003454"
    />
  </svg>
);

export const RadioFilled = (
  <svg
    id="Radio"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g
      id="Ellipse_1"
      data-name="Ellipse 1"
      fill="none"
      stroke="#004f6f"
      strokeWidth="4"
    >
      <circle cx="16" cy="16" r="16" stroke="none" />
      <circle cx="16" cy="16" r="14" fill="none" />
    </g>
    <circle
      id="Ellipse_3"
      data-name="Ellipse 3"
      cx="10"
      cy="10"
      r="10"
      transform="translate(6 6)"
      fill="#004f6f"
    />
  </svg>
);
export const RadioOutlined = (
  <svg
    id="Radio"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g
      id="Ellipse_1"
      data-name="Ellipse 1"
      fill="none"
      stroke="#004f6f"
      strokeWidth="4"
    >
      <circle cx="16" cy="16" r="16" stroke="none" />
      <circle cx="16" cy="16" r="14" fill="none" />
    </g>
  </svg>
);
export const XCircled = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46.068"
    height="46.068"
    viewBox="0 0 46.068 46.068"
  >
    <g id="cancel" transform="translate(0 -340.932)">
      <g id="Group_22" data-name="Group 22" transform="translate(0 340.932)">
        <path
          id="Path_79"
          data-name="Path 79"
          d="M39.32,6.747A23.033,23.033,0,1,0,6.747,39.32,23.033,23.033,0,1,0,39.32,6.747ZM37.469,37.469A20.414,20.414,0,0,1,8.6,8.6a20.414,20.414,0,1,1,28.87,28.87Z"
          transform="translate(0 0)"
          fill="#868585"
        />
        <path
          id="Path_80"
          data-name="Path 80"
          d="M125.681,105.381a1.3,1.3,0,0,0-1.852,0l-8.3,8.3-8.3-8.3a1.309,1.309,0,1,0-1.852,1.852l8.3,8.3-8.3,8.3a1.314,1.314,0,0,0,.921,2.239,1.277,1.277,0,0,0,.921-.388l8.3-8.3,8.3,8.3a1.317,1.317,0,0,0,.921.388,1.261,1.261,0,0,0,.921-.388,1.3,1.3,0,0,0,0-1.852l-8.279-8.3,8.3-8.3A1.3,1.3,0,0,0,125.681,105.381Z"
          transform="translate(-92.497 -92.497)"
          fill="#868585"
        />
      </g>
    </g>
  </svg>
);

export const InternetExplorerLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="223"
    height="219"
    viewBox="0 0 223 219"
  >
    <path
      d="M219 114c0-17-4.4-33-12-47 33-74-35-63-39-63-14 2.8-28 7.3-40 13-46-.55-86 24-99 75 24-27 41-38 51-42-2.1 1.9-4.2 3.9-6.3 5.9-16 16-29 32-40 49-8.5 15-18 28-23 45-27 98 58 57 70 50 13 6.4 27 9.9 43 9.9 42 0 78-27 91-64h-51c-24 38-81 21-83-20h138c.52-4.1.8-8.2.8-12zm-18-95c8.3 5.6 15 14 3.5 44-11-18-27-32-47-39 8.9-4.3 31-13 44-5zM24 200c-6.8-6.9-8-24 7-55 7.5 22 23 40 42 51-9.7 5.3-35 17-49 3.3zm56-100c.77-22 20-40 44-40s43 18 44 40H81z"
      fill="#1ebbee"
    />
  </svg>
);

export const User = (
  <svg
    id="user"
    xmlns="http://www.w3.org/2000/svg"
    width="18.25"
    height="18.25"
    viewBox="0 0 18.25 18.25"
  >
    <g id="Group_19" data-name="Group 19">
      <path
        id="Path_70"
        data-name="Path 70"
        d="M15.577,11.8A9.09,9.09,0,0,0,12.11,9.623a5.275,5.275,0,1,0-5.97,0A9.139,9.139,0,0,0,0,18.25H1.426a7.7,7.7,0,1,1,15.4,0H18.25A9.066,9.066,0,0,0,15.577,11.8ZM9.125,9.125a3.85,3.85,0,1,1,3.85-3.85A3.854,3.854,0,0,1,9.125,9.125Z"
        transform="translate(0)"
        fill="#868585"
      />
    </g>
  </svg>
);

export const Cog = (
  <svg
    id="settings"
    xmlns="http://www.w3.org/2000/svg"
    width="15.336"
    height="15.336"
    viewBox="0 0 15.336 15.336"
  >
    <g id="Group_21" data-name="Group 21">
      <g id="Group_20" data-name="Group 20">
        <path
          id="Path_71"
          data-name="Path 71"
          d="M14.335,6H13.087a5.642,5.642,0,0,0-.408-.986l.882-.882a1,1,0,0,0,0-1.415l-.943-.943a1.025,1.025,0,0,0-1.415,0l-.882.882a5.657,5.657,0,0,0-.986-.408V1a1,1,0,0,0-1-1H7A1,1,0,0,0,6,1V2.248a5.668,5.668,0,0,0-.986.408l-.882-.882a1,1,0,0,0-1.415,0l-.944.943a1,1,0,0,0,0,1.415l.882.882A5.657,5.657,0,0,0,2.248,6H1A1,1,0,0,0,0,7V8.335a1,1,0,0,0,1,1H2.248a5.668,5.668,0,0,0,.408.986l-.882.882a1,1,0,0,0,0,1.415l.943.944a1,1,0,0,0,1.415,0l.882-.882A5.657,5.657,0,0,0,6,13.088v1.248a1,1,0,0,0,1,1H8.335a1,1,0,0,0,1-1V13.087a5.657,5.657,0,0,0,.986-.408l.882.882a1.026,1.026,0,0,0,1.415,0l.943-.943a1,1,0,0,0,0-1.415l-.882-.882a5.657,5.657,0,0,0,.408-.986h1.248a1,1,0,0,0,1-1V7A1,1,0,0,0,14.335,6Zm.333,2.334a.334.334,0,0,1-.333.333h-1.5a.333.333,0,0,0-.323.25,5.011,5.011,0,0,1-.535,1.291.334.334,0,0,0,.051.405l1.06,1.06a.335.335,0,0,1,0,.472l-.943.943a.343.343,0,0,1-.472,0l-1.06-1.06a.334.334,0,0,0-.405-.051,4.994,4.994,0,0,1-1.291.534.333.333,0,0,0-.25.322v1.5a.334.334,0,0,1-.333.333H7a.334.334,0,0,1-.333-.333v-1.5a.333.333,0,0,0-.25-.323,5,5,0,0,1-1.291-.534.33.33,0,0,0-.169-.046.333.333,0,0,0-.236.1l-1.06,1.06a.334.334,0,0,1-.472,0l-.943-.943a.334.334,0,0,1,0-.472l1.06-1.06a.333.333,0,0,0,.051-.405,5.005,5.005,0,0,1-.534-1.291.333.333,0,0,0-.322-.25H1a.334.334,0,0,1-.333-.333V7A.334.334,0,0,1,1,6.668H2.5a.333.333,0,0,0,.323-.25,5,5,0,0,1,.534-1.291.333.333,0,0,0-.051-.405l-1.06-1.06a.334.334,0,0,1,0-.472l.943-.943a.334.334,0,0,1,.472,0l1.06,1.06a.332.332,0,0,0,.405.051,5.005,5.005,0,0,1,1.291-.534.333.333,0,0,0,.25-.323V1A.334.334,0,0,1,7,.667H8.335A.334.334,0,0,1,8.668,1V2.5a.333.333,0,0,0,.25.323,4.994,4.994,0,0,1,1.291.534.333.333,0,0,0,.405-.051l1.06-1.06a.342.342,0,0,1,.472,0l.943.943a.335.335,0,0,1,0,.472l-1.06,1.06a.333.333,0,0,0-.051.405,5.006,5.006,0,0,1,.535,1.291.333.333,0,0,0,.322.25h1.5A.334.334,0,0,1,14.669,7Z"
          fill="#868585"
        />
      </g>
    </g>
    <g id="Group_23" data-name="Group 23" transform="translate(4.667 4.667)">
      <g id="Group_22" data-name="Group 22">
        <path
          id="Path_72"
          data-name="Path 72"
          d="M115,112a3,3,0,1,0,3,3A3,3,0,0,0,115,112Zm0,5.334A2.334,2.334,0,1,1,117.334,115,2.336,2.336,0,0,1,115,117.334Z"
          transform="translate(-112 -112)"
          fill="#868585"
        />
      </g>
    </g>
  </svg>
);

export const EmailSupport = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.435"
    height="18.291"
    viewBox="0 0 20.435 18.291"
  >
    <path
      id="mail"
      d="M20.427,32.648v8.686a2.249,2.249,0,0,1-2.246,2.246H2.246A2.249,2.249,0,0,1,0,41.335V30.147A2.249,2.249,0,0,1,2.246,27.9h9.879a.382.382,0,0,1,0,.763H2.246A1.485,1.485,0,0,0,.763,30.147v1.137l8.728,4.239a1.644,1.644,0,0,0,1.444,0l4.265-2.07a.382.382,0,1,1,.333.687l-4.265,2.07a2.4,2.4,0,0,1-2.111,0L.763,32.133v9.2a1.485,1.485,0,0,0,1.483,1.483H18.18a1.485,1.485,0,0,0,1.483-1.483V32.648a.382.382,0,1,1,.763,0ZM5.96,37.081,2.241,40.8a.382.382,0,0,0,.54.54L6.5,37.621a.382.382,0,1,0-.54-.54Zm7.967,0a.382.382,0,0,0,0,.54l3.719,3.719a.382.382,0,0,0,.54-.54l-3.719-3.719a.382.382,0,0,0-.54,0Zm3.122-5.02a3.4,3.4,0,0,0,2.473-1.073.382.382,0,0,0-.557-.521,2.622,2.622,0,1,1,.707-1.791.382.382,0,0,0,.763,0,3.386,3.386,0,1,0-3.386,3.386Zm.573-2.94a1.272,1.272,0,1,0-1.851-1.133.382.382,0,0,0,.763,0,.5.5,0,0,1,.16-.371.509.509,0,1,1,.58.824,1.127,1.127,0,0,0-.568.677.382.382,0,1,0,.732.217A.361.361,0,0,1,17.622,29.121Zm-.953,1.127a.381.381,0,1,0,.726-.071A.382.382,0,0,0,16.669,30.248Z"
      transform="translate(0 -25.29)"
      fill="#00446b"
    />
  </svg>
);
